import { Routes, Route } from "react-router-dom";
import ChannelList from "./ChannelList";
import WebhookList from "./WebhookList";
import ContentCSS from "../css/content.module.css";

function Content() {
  return (
    <div className={ContentCSS["content"]}>
      <ChannelList />

      <Routes>
        <Route path=":channelID" element={<WebhookList />} />
      </Routes>
    </div>
  );
}

export default Content;