import "../css/Default Styles/middle-section.css";
import "../css/Desktop Styles/middle-section.css";
import "../css/Tablet Styles/middle-section.css"
import "../css/Mobile Styles/middle-section.css";
import "aos/dist/aos.css";

function MiddleSection() {
  return (
    <div id="features" className="middle-section">
      <div className="middle-section-title-container">
        <h1 className="middle-section-title" data-aos-offset="0" data-aos="zoom-out"><span>Elden</span> Monitors are <span>Discord</span>'s most powerful and fastest network product monitoring robots</h1>
        <h2 className="middle-section-subtitle" data-aos-offset="0" data-aos="zoom-out">Covering all Nike online and in store drops and restocks</h2>
      </div>
      <div className="middle-section-container">
        <div className="card" data-aos-offset="0" data-aos="fade" data-aos-duration="100" data-aos-delay="10">
          <h3>Unlimited Product Coverage</h3>
          <p>Database covering hundreds of thousand of SKUs, starting from 1989</p>
        </div>
        <div className="card" data-aos-offset="0" data-aos="fade" data-aos-duration="200" data-aos-delay="20">
          <h3>Real Time Data For All Product</h3>
          <p>Frontend Data, Back-end Data, Launch Data and more!</p>
        </div>
        <div className="card" data-aos-offset="0" data-aos="fade" data-aos-duration="300" data-aos-delay="30">
          <h3>Stock Number Information</h3>
          <p>Exact stock count for all Nike products for most Nike regions!</p>
        </div>
        <div className="card" data-aos-offset="0" data-aos="fade" data-aos-duration="400" data-aos-delay="40">
          <h3>Countless tools</h3>
          <p>-Nike in-store stock checker <br></br>-Nike online SKU checker <br></br>-StockX tool<br></br>-ATC tool <br></br>and much more!</p>
        </div>
      </div>
      <p data-aos-offset="0" data-aos="zoom" data-aos-duration="400" className="extra-text">
        Our team of experts is dedicated to providing you with the most comprehensive monitoring service available, giving you an edge in the highly competitive world of sneaker reselling
      </p>
    </div>
  );
}

export default MiddleSection;