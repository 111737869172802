import exampleVideo from "../assets/example_video.mp4";
import exampleImage from "../assets/example_image.jpg";
import discordIcon from "../assets/discord-blue.svg";
import "../css/Default Styles/top-section.css";
import "../css/Desktop Styles/top-section.css"
import "../css/Tablet Styles/top-section.css";
import "../css/Mobile Styles/top-section.css";
import "aos/dist/aos.css";

function TopSection() {
  return (
    <div id="home" className="top-section">
      <video data-aos-offset="0" className="example-video" loop muted autoPlay playsInline src={exampleVideo} poster={exampleImage} data-aos="fade" data-aos-duration="300" data-once="false"></video>
      <div className="top-section-container">
        <h1 data-aos-offset="0" className="top-section-title" data-aos="zoom" data-aos-duration="1000">Welcome to <span>Elden</span> Monitors! </h1>
        <p data-aos-offset="0" data-aos="zoom" data-aos-duration="500">We're thrilled to have you here and excited to introduce you to our premium Nike monitoring service</p>
        <button onClick={()=>{ window.open("https://discord.gg/g3W3WHpwus", "_blank")}} tabIndex="-1" data-aos-offset="0" data-aos="zoom" data-aos-duration="500" className="discord-button"><img className="discord-icon" target="_blank" src={discordIcon} alt="discord-logo" />Join Today</button>
      </div>
      <video data-aos-offset="0" className="second-example-video" loop muted autoPlay playsInline src={exampleVideo} poster={exampleImage} data-aos="fade" data-aos-duration="300" data-once="false"></video>
      <p data-aos-offset="0" data-aos="zoom" data-aos-duration="400" className="extra-text">
        At Elden Monitors, we pride ourselves on being fast and reliable, ensuring that our customers receive real-time updates on the latest Nike releases
      </p>
    </div>
  );
}

export default TopSection;