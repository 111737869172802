import axios from "axios";
import { Edit, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import UpdateModal, {
  EditBlacklistModal,
  EditPromoCodeModal,
  NewBlacklistModal,
  NewModal,
  NewPromoCodeModal,
} from "../../Settings/components/Modal";
import TwitterCSS from "../css/twitter.module.css";

const typeValue = {
  state: "State Change",
  price: "Price Change",
  restock: "Restock",
};

function Twitter() {
  const [keywords, setKeywords] = useState([]);
  const [newKeywordModal, setNewKeywordModal] = useState(false);
  const [blacklist, setBlacklist] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [newPromocodeModal, setNewPromocodeModal] = useState(false);

  useEffect(() => {
    async function getData() {
      const res = await axios.get("/twitter/keywords");
      const data = await res.data;

      console.log(data);

      if (data?.data?.keywords?.length > 0) {
        setKeywords(data.data.keywords);
        setPromocodes(data.data.promocodes);
        setBlacklist(data.data.blacklist);
      }
    }
    getData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "3rem",
      }}
    >
      <div className={TwitterCSS["root-twitter"]}>
        {/* <div
          style={{
            backgroundColor: "#343434",
            borderRadius: "10px",
            display: "flex",
            color: "#cacaca",
            flexDirection: "column",
            padding: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
            textAlign: "start",
            width: "100%",
          }}
        >
          <span
            style={{
              color: "#fcfaf1",
              fontSize: "22px",
              fontWeight: "500",
              marginBottom: "6px",
              textAlign: "center",
              letterSpacing: "1.6px",
            }}
          >
            Promo Code
          </span>
          {promocodes.length > 0
            ? promocodes.map((el, i) => (
                <div key={i}>
                  {el.promocode} {el.description}
                  <Edit size={24} />
                </div>
              ))
            : ""}
        </div> */}

        <div className={TwitterCSS["promocode"]}>
          <p
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "start",
              width: "100%",
            }}
          >
            Promo Code:
            <span>
              {" "}
              {promocodes.length > 0
                ? promocodes.map((el, i) => (
                    <PromoCodeBox
                      el={el}
                      key={i}
                      i={i}
                      setPromocodes={setPromocodes}
                    />
                  ))
                : ""}
            </span>
            <span style={{ marginLeft: "auto" }}>
              <Plus
                style={{ cursor: "pointer" }}
                onClick={() => setNewPromocodeModal(true)}
                color="white"
              />
            </span>
          </p>
          <NewPromoCodeModal
            open={newPromocodeModal}
            setOpen={setNewPromocodeModal}
            setPromocodes={setPromocodes}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {keywords.length > 0
            ? keywords.map((el, i) => (
                <div style={{ display: "flex", flexWrap: "wrap" }} key={i}>
                  <TwitterBox
                    setKeywords={setKeywords}
                    el={el}
                    key={i}
                    active={i}
                    blacklist={blacklist.filter(
                      (bl) => bl.category === el.category
                    )}
                    setBlacklist={setBlacklist}
                  />
                </div>
              ))
            : ""}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setNewKeywordModal(true)}
        >
          <h4>Insert a new keyword</h4>
        </div>
      </div>
      <NewModal
        open={newKeywordModal}
        setOpen={setNewKeywordModal}
        setKeywords={setKeywords}
      />
    </div>
  );
}

function PromoCodeBox({ el, setPromocodes }) {
  const [editPromocodeModal, setEditPromocodeModal] = useState(false);
  return (
    <React.Fragment>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          justifyContent: "space-between",
          marginLeft: "10px",
        }}
      >
        <span>
          {el.promocode} {el.description}
        </span>{" "}
        <Edit
          style={{ cursor: "pointer" }}
          size={24}
          onClick={() => setEditPromocodeModal(true)}
        />
      </span>
      <EditPromoCodeModal
        open={editPromocodeModal}
        setOpen={setEditPromocodeModal}
        id={el._id}
        currPromocode={el.promocode}
        currDescription={el.description}
        setPromocodes={setPromocodes}
      />
    </React.Fragment>
  );
}

function TwitterBox({ el, setKeywords, blacklist, setBlacklist }) {
  const [isTweetingOn, setIsTweetingOn] = useState(el.istwitterkeywordon);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isNewBlacklistOpen, setIsNewBlacklistOpen] = useState(false);

  async function handleIsTweetingOn(id, istweetingon) {
    const res = await axios.post("/twitter/update-keywords-status", {
      id,
      istweetingon: !istweetingon,
    });
    const data = await res.data;

    if (data.status === 200) {
      setIsTweetingOn(!istweetingon);
    }
  }

  return (
    <div className={TwitterCSS["region-container"]}>
      <div className={TwitterCSS["region"]}>
        <div
          style={{
            alignItems: "start",
            alignContent: "start",
            backgroundColor: "#272727a6",
            borderStyle: "solid",
            borderRadius: "6px",
            borderColor: "#FCFAF1",
            justifyContent: "start",
            paddingLeft: "8px",
            paddingRight: "8px",
            height: "100%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
                // color: isTweetingOn ? "#aafe97" : "#e95252",
              }}
              className={TwitterCSS["region-title"]}
            >
              {el.category.slice(0, 1).toUpperCase() + el.category.slice(1)}{" "}
              Monitor
            </h2>
            <div className={TwitterCSS["turn-onoff-tweeting"]}>
              <div className={TwitterCSS["parameter-checkbox-box"]}>
                {/* <input
                  className={TwitterCSS["parameter-checkbox"]}
                  checked={isTweetingOn}
                  value={isTweetingOn}
                  onClick={(e) => e.stopPropagation()}
                  onChange={async () => {
                    setIsTweetingOn((prevState) => {
                      axios.post("/twitter/update-keywords-status", {
                        id: el._id,
                        istweetingon: !prevState,
                      });

                      return !prevState;
                    });
                  }}
                  type="checkbox"
                /> */}

                <svg
                  height="20"
                  width="20"
                  className={TwitterCSS["blinking"]}
                  onClick={() => handleIsTweetingOn(el._id, isTweetingOn)}
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   handleIsTweetingOn();

                  //   // setIsTweetingOn((prevState) => {

                  //   //   axios.post("/twitter/update-keywords-status", {
                  //   //     id: el._id,
                  //   //     istweetingon: !prevState,
                  //   //   });

                  //   //   return !prevState;
                  //   // });
                  // }}
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="10"
                    fill={isTweetingOn ? "green" : "red"}
                    style={{ cursor: "pointer" }}
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={TwitterCSS["keywords"]}>
              <div className={TwitterCSS["blacklist"]}>
                <p
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "start",
                    width: "100%",
                  }}
                >
                  Blacklist:
                  <span>
                    {" "}
                    {blacklist.length > 0
                      ? blacklist.map((el, i) => (
                          <BlacklistBox
                            el={el}
                            key={i}
                            setBlacklists={setBlacklist}
                          />
                        ))
                      : ""}
                  </span>
                  <span style={{ marginLeft: "auto" }}>
                    <Plus
                      style={{ cursor: "pointer" }}
                      color="white"
                      onClick={() => setIsNewBlacklistOpen(true)}
                    />
                  </span>
                </p>
                <NewBlacklistModal
                  category={el.category}
                  open={isNewBlacklistOpen}
                  setOpen={setIsNewBlacklistOpen}
                  setBlacklists={setBlacklist}
                  key={Math.random()}
                />
              </div>
            </div>

            {el.keywords.map((keyword, i) => (
              <KeywordBox
                keyword={keyword}
                setGlobalKeywords={setKeywords}
                el={el}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function BlacklistBox({ el, setBlacklists }) {
  const [isUpdateBlacklistModalOpen, setIsUpdateBlacklistModalOpen] =
    useState(false);

  return (
    <>
      <div>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <span>{el.blacklistword}</span>{" "}
          <Edit
            style={{ cursor: "pointer" }}
            size={24}
            onClick={() => setIsUpdateBlacklistModalOpen(true)}
          />
        </span>
        <EditBlacklistModal
          blacklistWord={el.blacklistword}
          open={isUpdateBlacklistModalOpen}
          setOpen={setIsUpdateBlacklistModalOpen}
          setBlacklists={setBlacklists}
          key={Math.random()}
          category={el.category}
          id={el._id}
        />
      </div>
    </>
  );
}

function KeywordBox({ keyword, el, setGlobalKeywords }) {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  return (
    <>
      <div
        className={TwitterCSS["keywords"]}
        onClick={() => setIsUpdateModalOpen(true)}
      >
        <div className={TwitterCSS["keyword"]}>
          <div className={TwitterCSS["name"]}>
            <p>
              Name: <span>{keyword.title}</span>
            </p>
          </div>
          <div className={TwitterCSS["whitelist"]}>
            <p>
              Change Type: <span>{typeValue[keyword.changeType]}</span>
            </p>
          </div>
          <div className={TwitterCSS["whitelist"]}>
            <p>
              Cooldown:{" "}
              <span>
                {keyword.cooldown === 0 ? "No Cooldown" : keyword.cooldown}
              </span>
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#FCFAF1",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "10px",
            width: "100%",
          }}
        ></div>
      </div>
      <UpdateModal
        key={Math.random()}
        open={isUpdateModalOpen}
        setOpen={setIsUpdateModalOpen}
        product={{
          keywords: keyword.title,
          changeType: keyword.changeType,
          id: el._id,
          keywordsId: keyword.id,
          cooldown: keyword.cooldown,
        }}
        setGlobalKeywords={setGlobalKeywords}
      />
    </>
  );
}

export default Twitter;
