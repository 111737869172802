import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import NikeShoesRefurbishedCSS from "../css/nikeShoesRefurbished.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function NikeShoesRefurbished() {
  // Region US
  const [shoesInputValue, setShoesInputValue] = useState({
    US: [],
    USCustom: []
  });

  const [regionList] = useState(["US", "USCustom"]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [skuURLInputValue, setSKUURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);
  const [switchMenu, setSwitchMenu] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const status = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (status) {
      case "SHOES":
        data = shoesInputValue[region].find(el => el.label === label);
        break;
    
      default:
        break;
    }
    
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=refurbished`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          status,
          data: {label: data.label, value: data.value}
        }
      };

      axios(config).then((res) => {
        console.log(res.data);
        setReloadWebhooks(!reloadWebhooks);
      }).catch((err) => { console.log(err); });
    } catch (error) { }
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=refurbished`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status,
          data: {label: label, value: webhookURL},
        },
      };

      axios(config).then(() => {
        setLabelInputValue("");
        setWebhookURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);
      }).catch(() => { });
    } catch (error) { }
  };

  const handleNewSKUSubmit = (e) => {
    e.preventDefault();

    const sku = skuURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    console.log(sku, region, status);

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=USCustom&category=refurbished`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status: sku,
          data: {label: sku, value: sku},
        },
      };

      axios(config).then((res) => {
        console.log(res.data);
        setSKUURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);
      }).catch((err) => { console.log(err);});
    } catch (error) { }
  };

  useEffect(() => {
    setShowWebhooks([]);
    
    const newShoesInputValue = { ...shoesInputValue };

    regionList.forEach((region, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/nikewebhooks?region=${region}&category=refurbished`,
        };

        axios(config)
          .then((res) => {
            if (region === "US") {
              newShoesInputValue[region] = res.data[0].webhooks.map(webhook => {return {label: webhook.label, value: webhook.value, readOnly: true}});
            } else if (region === "USCustom") {
              newShoesInputValue[region] = res.data.map(el => {return {label: el.sku, value: el.sku, readOnly: true}});
            }

            setShoesInputValue(newShoesInputValue);
          }).catch(() => {}).finally(() => setShowWebhooks(prevState => [...prevState, 1]));
      }, index * 100);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  }, [uploadMenu]);

  return (
    <div className={NikeShoesRefurbishedCSS["root-nike-shoes"]}>
      <div className={NikeShoesRefurbishedCSS["region-container"]}>
        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeShoesRefurbishedCSS["first-background"]}></div> : <></>}

        {showWebhooks.length === regionList.length ? <>
          {regionList.map((region, index) => {
            let title = 'unknown'
            switch (region) {
              case "US":
                title = "US Refurbished";
                break;
              case "USCustom":
              title = "US Refurbished - Custom";
              break;

              default:
                break;
            }

            return (
              <div className={NikeShoesRefurbishedCSS["region-box"]} key={index}>
                <h2 className={NikeShoesRefurbishedCSS["region-title"]}>Nike {title}</h2>

                <div className={NikeShoesRefurbishedCSS["webhook"]}>
                  <h3 className={NikeShoesRefurbishedCSS["webhook-status"]}> {region === "USCustom" ? "SKUs" : "Shoes Webhooks"}</h3>
                  {shoesInputValue[region].map((webhook, index) => {
                    return (
                      <form className={NikeShoesRefurbishedCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                        <div className={NikeShoesRefurbishedCSS["webhook-grid"]}>
                          <label className={NikeShoesRefurbishedCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                          <div className={NikeShoesRefurbishedCSS["webhook-row"]}>
                            <input
                              className={NikeShoesRefurbishedCSS["webhook-input"]}
                              value={webhook.value}
                              name={region}
                              type="url"
                              id={webhook.label}
                              required 
                              onChange={e => {
                                if (e.target.name === region) {
                                  let newShoesInputValue = { ...shoesInputValue };
                                  const updatedWebhook = shoesInputValue[region].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                    } else {
                                      return el;
                                    }
                                  });
                                  newShoesInputValue[region] = updatedWebhook;
                                  setShoesInputValue(newShoesInputValue);
                                }
                              }}
                              disabled={shoesInputValue[region].find(el => el.label === webhook.label).readOnly}
                            />
                            <button className={NikeShoesRefurbishedCSS["webhook-edit-btn"]} id={webhook.label} name={region} type="button"
                              onClick={e => {
                                let newShoesInputValue = { ...shoesInputValue };
                                const updatedWebhook = shoesInputValue[e.currentTarget.name].map(el => {
                                  if (el.label === webhook.label) {
                                    return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                  } else {
                                    return el;
                                  }
                                });
                                newShoesInputValue[e.currentTarget.name] = updatedWebhook;
                                setShoesInputValue(newShoesInputValue);
                              }}
                            ><img className={NikeShoesRefurbishedCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                            <button className={NikeShoesRefurbishedCSS["webhook-save-btn"]} id={webhook.label} name={region + "-SHOES-UPDATE"} type="submit"
                              onClick={handleSubmit}
                            ><img className={NikeShoesRefurbishedCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                            <button className={NikeShoesRefurbishedCSS["webhook-delete-btn"]} id={webhook.label} name={region + "-SHOES-DELETE"} type="button"
                              onClick={handleSubmit}
                            ><img className={NikeShoesRefurbishedCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                          </div>
                        </div>
                      </form>
                    );
                  })}
                </div>
              </div>
            )
          })} </> : <div className={NikeShoesRefurbishedCSS["access-spin"]}><span className={NikeShoesRefurbishedCSS["access-spinner"]}></span></div>}

        <div className={NikeShoesRefurbishedCSS["bottom-container"]}>
          <div className={NikeShoesRefurbishedCSS["menu-box"]}>
            <div onClick={() => setUploadMenu(!uploadMenu)} className={NikeShoesRefurbishedCSS["menu-btn"]}>
              <img className={NikeShoesRefurbishedCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${NikeShoesRefurbishedCSS["upload-container"]} ${uploadMenu ? NikeShoesRefurbishedCSS["upload-container-show"] : ""}`}>
            {/* <input type="checkbox" name="switch-menu" id="switch-menu" onChange={() => setSwitchMenu(!switchMenu)}/> */}
          
            {switchMenu ? 
              <>
                <h2 className={NikeShoesRefurbishedCSS["upload-form-title"]} htmlFor="region">Enter the new SKU</h2>
                
                <form className={NikeShoesRefurbishedCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewSKUSubmit}>
                  {/* <input className={NikeShoesRefurbishedCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="SKU" required /> */}
                  <input className={NikeShoesRefurbishedCSS["upload-input-sku"]} value={skuURLInputValue} onChange={e => setSKUURLInputValue(e.target.value)} type="text" name="sku" placeholder="SKU" required />
                  
                  <select className={NikeShoesRefurbishedCSS["upload-select-region"]} name="region">
                    <option value="US">United States</option>
                  </select>

                  {/* <select className={NikeShoesRefurbishedCSS["upload-select-status"]} name="status" value={["SKU"]} multiple readOnly>
                    <option value="SKU">SKU</option>
                  </select> */}

                  <button className={NikeShoesRefurbishedCSS["upload-submit-btn"]} type="submit">Upload</button>
                  <button className={NikeShoesRefurbishedCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
                </form>
              </>
              : 
              <>
                <h2 className={NikeShoesRefurbishedCSS["upload-form-title"]} htmlFor="region">Enter the new webhook</h2>
                
                <form className={NikeShoesRefurbishedCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewWebhookSubmit}>
                  <input className={NikeShoesRefurbishedCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
                  <input className={NikeShoesRefurbishedCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="text" name="webhook" placeholder="Webhook URL" required />
                  
                  <select className={NikeShoesRefurbishedCSS["upload-select-region"]} name="region">
                    <option value="US">United States</option>
                  </select>

                  <select className={NikeShoesRefurbishedCSS["upload-select-status"]} name="status" value={["SHOES"]} multiple readOnly>
                    <option value="SHOES">Shoes</option>
                  </select>

                  <button className={NikeShoesRefurbishedCSS["upload-submit-btn"]} type="submit">Upload</button>
                  <button className={NikeShoesRefurbishedCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
                </form>
              </>
            }

            <div className={NikeShoesRefurbishedCSS["switch"]} data-ison={switchMenu} onClick={() => setSwitchMenu(!switchMenu)}>
              <motion.div className={NikeShoesRefurbishedCSS["handle"]} layout 
                initial={{
                  y: "0px"
                }}
                tansition={{
                  type: "spring",
                  stiffness: 700,
                  damping: 30
                }}
              />
            </div>
          </div>
        </div>

        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeShoesRefurbishedCSS["second-background"]}></div> : <></>}
      </div>
    </div>
  );
}

export default NikeShoesRefurbished;
