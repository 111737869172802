import { useEffect, useState } from "react";
import axios from "axios";
import SubmitForm from "./components/SubmitForm";
import AOS from "aos";

function UserPortal() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    AOS.init({      
      offset: 300,
      duration: 100,
      easing: 'ease-in-out-quart',
    });
  }, []);

  useEffect(() => {
    axios.get("/api/userportal/user")
      .then(res => {
        if (res.data === "unauthorized") {
          window.location.replace("/auth");
        } else {
          setLoggedIn(true);
        }
      }).catch(() => window.location.replace("/"));
  }, []);

  return (
    loggedIn ? <SubmitForm /> : <></>
  )
}

export default UserPortal;