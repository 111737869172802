import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import WebhookCSS from "../css/webhooktab.module.css";
import editImg from "../assets/edit.svg";
import saveImg from "../assets/save.svg";
import addImg from "../assets/add.svg";
import deleteImg from "../assets/delete.svg";

function Channels() {
  const [webhooks, setWebhooks] = useState([]);
  const [readOnly, setReadOnly] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const channel = useSelector(store => store.channel);
  const guild = useSelector(store => store.guild);

  const handleForm = (event) => {
    event.preventDefault();

    axios.post(`/api/webhook${event.currentTarget.id}`, {
      "guildId": guild.guildId,
      "id": event.currentTarget[0].id,
      "region": channel.channelRegion,
      "toolsName": channel.channelName,
      "webHook": event.currentTarget[0].value
    }).then(() => {}).catch(() => {});

    if (event.currentTarget.id === "insert") {
      setTimeout(() => {
        setRefresh(!refresh);
        setInputValue("");
      }, 1000);
    };
  };

  const handleClick = event => {
    const webhookArray = readOnly.map(webhook => {
      if (event.currentTarget.id === String(webhook.id)) {
        return {id: webhook.id, readOnly: !webhook.readOnly};
      } else {
        return webhook;
      }
    });

    setReadOnly(webhookArray);
  };

  const handleClickDelete = event => {
    axios.post(`/api/webhookdelete`, {
      "guildId": guild.guildId,
      "id": event.currentTarget.id
    }).then(() => {}).catch(() => {});

    setRefresh(!refresh);
  };

  useEffect(() => {
    setWebhooks([]);
    setInputValue("");
  }, [channel, refresh]);

  useEffect(() => {
    const { channelRegion, channelName, channelId } = channel;
    setReadOnly([]);

    if (channelRegion === "" || channelName === "" || channelId === "") return;

    axios.get(`/api/webhooklist?toolsName=${channelName}&region=${channelRegion}&guildId=${guild.guildId}`)
      .then(result => {
        setWebhooks(prevState => [...prevState, ...result.data.data]);
        result.data.data.forEach(webhook => {
          setReadOnly(prevState => [...prevState, {id: webhook.id, readOnly: true}]);
        });
      }).catch(() => {
        console.log('WEBHOOKLIST run');
        // window.location.replace("/")
      });
  }, [channel, guild, webhooks.webhook, refresh]);

  return (
    <div className={WebhookCSS["webhook-tab-list"]}>
      <div className={WebhookCSS["webhook-top"]}>
        <h5 className={WebhookCSS["webhook-title"]}>
          {guild.guildName.length ? "Webhooks Settings" : ""}
        </h5>
      </div>
      
      <div className={WebhookCSS["webhook-bottom"]}>
        {webhooks.length ? webhooks.map((result, index) => {
          return (
            <div key={index} className={WebhookCSS["webhook"]}>
              <form
                className={WebhookCSS["webhook-form"]}
                action="/"
                method="post"
                id="edit"
                onSubmit={handleForm}
              >
                <input onChange={event => {
                  webhooks.forEach(webhook => {
                    if (String(webhook.id) === event.target.id) {
                      const updatedWebhook = webhooks.map(webhook => {
                        if (String(webhook.id) === event.target.id) {
                          return {id: webhook.id, webhook: webhook.webhook = event.target.value};
                        } else {
                          return webhook;
                        }
                      });
                      setWebhooks(updatedWebhook);
                    }
                  });
                }} required disabled={readOnly[index].readOnly ? true : false} readOnly={readOnly[index].readOnly} value={webhooks.find(webhook => webhook.id === result.id).webhook} type="url" name="wehbook" id={result.id} />
                <button id={result.id} onClick={handleClick} type="reset"><img className={WebhookCSS["webhook-edit-img"]} src={editImg} alt="edit-img" /></button>
                <button type="submit"><img className={WebhookCSS["webhook-save-img"]} src={saveImg} alt="save-img" /></button>
                <button id={result.id} onClick={handleClickDelete} type="button"><img className={WebhookCSS["webhook-delete-img"]} src={deleteImg} alt="save-img" /></button>
              </form>
            </div>
          );
        }) : <></>}
        
        {channel.channelId ? 
          <div className={WebhookCSS["webhook-add"]}>
            <form
              className={WebhookCSS["webhook-add-form"]}
              action="/"
              method="post"
              id="insert"
              onSubmit={handleForm}
            >
              <input onChange={event => {setInputValue(event.target.value)}} value={inputValue} required type="url" name="wehbook" placeholder="Add a new webhook" />
              <button type="submit"><img className={WebhookCSS["webhook-add-img"]} src={addImg} alt="save-img" /></button>
            </form>
          </div> 
        : <></>}
      </div>
    </div>
  );
}

export default Channels;