import { useEffect, useState } from "react";
// import { Routes, Route } from "react-router-dom";
import axios from "axios";
import GeneralCSS from "./css/general.module.css";
import Header from "./components/Header";
// import RegionSettings from "./components/RegionSettings";
// import Webhooks from "./components/Webhooks";
import Skus from "./components/Skus";
import ProductQuery from "./components/ProductQuery";

function Dashboard() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios.get("/api/client/user")
    .then(res => {
      if (res.data === "unauthorized") {
        window.location.replace("/auth");
      } else {
        setLoggedIn(res.data);
      }
    }).catch(() => window.location.replace("/"));
  }, []);

  return (
    <div className={GeneralCSS["client-dashboard"]}>
      {loggedIn ? (
        <>
          <Header />
          {/* <Webhooks /> */}
          <Skus data={loggedIn} />

          <ProductQuery data={loggedIn} />

          {/* <Routes>
            <Route path=":regionId" element={<RegionSettings />} />
          </Routes> */}
        </>
      ) : null}
    </div>
  );
}

export default Dashboard;
