import { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateGuild } from "../store/guildSlice";
import axios from "axios";
import SidebarCSS from "../css/sidebar.module.css";
import serverImg from "../assets/server.svg";
import userCircleImg from "../assets/user.svg";

function Dashboard() {
  const [guilds, setGuilds] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [dropDownList, setDropDownList] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = Object.values(useParams())[0].split("/")[0];

  const handleClick = (event) => {
    const guildArray = guilds.find(guild => guild.guildId === event.target.id);
    dispatch(updateGuild(guildArray));
  };

  const handleDropDownList = () => {
    setDropDownList(!dropDownList);
  };

  useEffect(() => {
    if (!guilds.length || !guilds || params === "") return;
    const guildArray = guilds.find(guild => guild.guildId === params);
    dispatch(updateGuild(guildArray));
  }, [dispatch, guilds, params]);

  useEffect(() => {
    axios.get("/api/guildlist")
      .then(res => {
        setGuilds([...guilds, ...res.data.data.data]);
      }).catch(() => {
        console.log("SIDEBAR run 2");
        // window.location.replace("/")
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios.get("/api/user")
      .then(res => {
        const { discordId, discriminator, avatar } = res.data;

        if (avatar === null) {
          setAvatar(`https://cdn.discordapp.com/embed/avatars/${discriminator % 5}.png`);
        } else {
          setAvatar(`https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`);
        }
      }).catch(() => {
        console.log('SIDEBAR run');
        // window.location.replace("/")
      });
  }, []);

  return (
    <div className={SidebarCSS["sidebar-tab"]}>
      <div className={SidebarCSS["sidebar-top"]}>
        {avatar ? <img onClick={handleDropDownList} className={SidebarCSS["author-img"]} src={avatar} alt="author-logo" /> : <img onClick={handleDropDownList} className={SidebarCSS["author-img"]} src={userCircleImg} alt="author-logo" /> }
        <h5 className={SidebarCSS["guild-title"]}>Servers</h5>
        <div className={`${SidebarCSS["dropdown-list"]} ${dropDownList ? SidebarCSS["show-dropdown-list"] : ""}`}>
          <p onClick={() => navigate("/dashboard/settings")}>Settings</p>
          <p onClick={() => { axios.get("/api/logout"); navigate("/") }}>Sign out</p>
        </div>
      </div>

      <div className={SidebarCSS["sidebar-bottom"]}>
        {guilds.map((result, index) => {
          return (
            <div key={index} className={SidebarCSS["guild"]}>
              <NavLink onClick={handleClick} to={result.guildId} className={({isActive}) => { return isActive ? SidebarCSS["guild-img-active"] : SidebarCSS["guild-img-inactive"]} }>
                <img
                  className={SidebarCSS["guild-img"]}
                  id={result.guildId}
                  src={result.guildImg ? result.guildImg : serverImg}
                  alt={result.guildName}
                />
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Dashboard;