import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import NikeEquipmentCSS from "../css/nikeEquipment.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function NikeEquipment() {
  const [activeInputValue, setActiveInputValue] = useState({
    US: [],
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: []
  });
  const [closeoutInputValue, setCloseoutInputValue] = useState({
    US: [],
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: []
  });
  const [holdInputValue, setHoldInputValue] = useState({
    US: [],
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: []
  });
  const [discountInputValue, setDiscountInputValue] = useState({
    US: []
  });

  const versionRegionList = "v0.0.1";
  const [regionList, setRegionList] = useState([
    { region: "US", folded: false },
    { region: "JP", folded: false }, 
    { region: "SG", folded: false }, 
    { region: "MY", folded: false }, 
    { region: "TH", folded: false }, 
    { region: "IN", folded: false },
    { region: "AU", folded: false },
    { region: "CA", folded: false },
    { region: "CN", folded: false },
    { region: "FR", folded: false },
    { region: "GB", folded: false },
    { region: "MX", folded: false },
    { region: "PH", folded: false },
    { region: "KR", folded: false },
    { region: "TW", folded: false },
    { region: "AT", folded: false }
  ]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  // const [activeInputValue, setActiveInputValue] = useState("");
  // const [closeoutInputValue, setCloseoutInputValue] = useState("");
  // const [holdInputValue, setHoldInputValue] = useState("");
  // const [isClicked, setIsClicked] = useState([false, false, false]);
  // const [showInfo, setShowInfo] = useState(["", "", ""]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const status = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (status) {
      case "ACTIVE":
        data = activeInputValue[region].find(el => el.label === label);
        break;
      case "CLOSEOUT":
        data = closeoutInputValue[region].find(el => el.label === label);
        break;
      case "HOLD":
        data = holdInputValue[region].find(el => el.label === label);
        break;
      case "DISCOUNT":
        data = discountInputValue[region].find(el => el.label === label);
        break;
    
      default:
        break;
    }
    
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=equipment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          status,
          data: {label: data.label, value: data.value}
        }
      };

      axios(config).then((res) => {
        setReloadWebhooks(!reloadWebhooks);

        console.log(res.data);
      }).catch(() => { });
    } catch (error) { }

    // const status = e.currentTarget.id;

    // switch (status) {
    //   case "ACTIVE":
    //     setIsClicked([true, isClicked[1], isClicked[2]]);
    //     break;
    //   case "CLOSEOUT":
    //     setIsClicked([isClicked[0], true, isClicked[2]]);
    //     break;
    //   case "HOLD":
    //     setIsClicked([isClicked[0], isClicked[1], true]);
    //     break;
    
    //   default:
    //     break;
    // }
    
    // setTimeout(() => {
    //   try {
    //     const data = JSON.stringify(JSON.parse(status === "ACTIVE" ? activeInputValue : status === "CLOSEOUT" ? closeoutInputValue : holdInputValue));
  
    //     const config = {
    //       method: "post",
    //       maxBodyLength: Infinity,
    //       url: "/api/nikewebhooks?region=US",
    //       headers: { 
    //         "Content-Type": "application/json"
    //       },
    //       data: {
    //         status,
    //         data
    //       }
    //     };
  
    //     axios(config)
    //       .then((res) => {
    //         switch (status) {
    //           case "ACTIVE":
    //             setShowInfo(["sent", showInfo[1], showInfo[2]]);
    //             break;
    //           case "CLOSEOUT":
    //             setShowInfo([showInfo[0], "sent", showInfo[2]]);
    //             break;
    //           case "HOLD":
    //             setShowInfo([showInfo[0], showInfo[1], "sent"]);
    //             break;
            
    //           default:
    //             break;
    //         }
    //       }).catch((err) => {
    //         switch (status) {
    //           case "ACTIVE":
    //             setShowInfo(["error", showInfo[1], showInfo[2]]);
    //             break;
    //           case "CLOSEOUT":
    //             setShowInfo([showInfo[0], "error", showInfo[2]]);
    //             break;
    //           case "HOLD":
    //             setShowInfo([showInfo[0], showInfo[1], "error"]);
    //             break;
            
    //           default:
    //             break;
    //         }
    //       });
    //   } catch (error) {
    //     switch (status) {
    //       case "ACTIVE":
    //         setShowInfo(["error", showInfo[1], showInfo[2]]);
    //         break;
    //       case "CLOSEOUT":
    //         setShowInfo([showInfo[0], "error", showInfo[2]]);
    //         break;
    //       case "HOLD":
    //         setShowInfo([showInfo[0], showInfo[1], "error"]);
    //         break;
        
    //       default:
    //         break;
    //     }
    //   }

    //   switch (status) {
    //     case "ACTIVE":
    //       setIsClicked([false, isClicked[1], isClicked[2]]);
    //       break;
    //     case "CLOSEOUT":
    //       setIsClicked([isClicked[0], false, isClicked[2]]);
    //       break;
    //     case "HOLD":
    //       setIsClicked([isClicked[0], isClicked[1], false]);
    //       break;
      
    //     default:
    //       break;
    //   }
    // }, 1000);
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=equipment`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status,
          data: {label: label, value: webhookURL},
        },
      };

      axios(config).then((res) => {
        setLabelInputValue("");
        setWebhookURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);
      }).catch(() => { });
    } catch (error) { console.log(error)}
  };

  useEffect(() => {
    setShowWebhooks([]);
    
    const newActiveInputValue = { ...activeInputValue };
    const newCloseoutInputValue = { ...closeoutInputValue };
    const newHoldInputValue = { ...holdInputValue };
    const newDiscountInputValue = { ...discountInputValue };

    regionList.forEach((el, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/nikewebhooks?region=${el.region}&category=equipment`,
        };

        axios(config)
          .then((res) => {
            newActiveInputValue[el.region] = res.data[0].webhooks.map(webhook => {return {label: webhook.label, value: webhook.value, readOnly: true}});
            newCloseoutInputValue[el.region] =  res.data[1].webhooks.map(webhook => {return {label: webhook.label, value: webhook.value, readOnly: true}});
            newHoldInputValue[el.region] = res.data[2].webhooks.map(webhook => {return {label: webhook.label, value: webhook.value, readOnly: true}});
            newDiscountInputValue[el.region] = res.data[3].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });

            setActiveInputValue(newActiveInputValue);
            setCloseoutInputValue(newCloseoutInputValue);
            setHoldInputValue(newHoldInputValue);
            setDiscountInputValue(newDiscountInputValue);
          }).catch(() => {}).finally(() => setShowWebhooks(prevState => [...prevState, 1]));
      }, index * 100);
    });

    // const config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: "/api/nikewebhooks?region=US",
    //   headers: { }
    // };
    
    // axios(config)
    //   .then(res => {
    //     setActiveInputValue(JSON.stringify(res.data[0].webhooks));
    //     setCloseoutInputValue(JSON.stringify(res.data[1].webhooks));
    //     setHoldInputValue(JSON.stringify(res.data[2].webhooks));
    //   }).catch(() => { });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowInfo(["", "", ""]);
  //   }, 8000);
  // }, [showInfo]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  }, [uploadMenu]);

  useEffect(() => {
    const storedRegionList = JSON.parse(localStorage.getItem(`nikeequipment${versionRegionList}`)) || [];

    if (!storedRegionList.length) {
      localStorage.setItem(`nikeequipment${versionRegionList}`, JSON.stringify(regionList))
    } else {
      setRegionList(storedRegionList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variants = {
    open: { opacity: 1, x: 0, display: "flex" },
    closed: { opacity: 0, x: "0%", display: "none" },
  }

  return (
    <div className={NikeEquipmentCSS["root-nike-equipment"]}>
      <div className={NikeEquipmentCSS["region-container"]}>
        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeEquipmentCSS["first-background"]}></div> : <></>}

        {showWebhooks.length === regionList.length ? <>
          {regionList.map((el, index) => {
            let title = 'unknown'
            switch (el.region) {
              case "US":
                title = "United States";
                break;
              case "JP":
              title = "Japan";
              break;
              case "SG":
                title = "Singapore";
                break;
              case "MY":
                title = "Malaysia";
                break;
              case "TH":
                title = "Thailand";
                break;
              case "IN":
                title = "India";
                break;
              case "AU":
                title = "Australia";
                break;
              case "CA":
                title = "Canada";
                break;
              case "CN":
                title = "China";
                break;
              case "FR":
                title = "France";
                break;
              case "GB":
                title = "United Kingdom";
                break;
              case "MX":
                title = "Mexico";
                break;
              case "PH":
                title = "Philippines";
                break;
              case "KR":
                title = "Korea";
                break;
              case "TW":
                title = "Taiwan";
                break;
              case "AT":
                title = "Austria";
                break;

              default:
                break;
            }

            return (
              <div className={NikeEquipmentCSS["region-box"]} key={index}>
                <h2 
                  className={NikeEquipmentCSS["region-title"]}
                  onClick={() => {
                    let newRegionList = { ...regionList };
                    const updatedRegionList = regionList.map(e => {
                      if (e.region === el.region) {
                        return { region: el.region, folded: !e.folded };
                      } else {
                        return e;
                      }
                    });
                    newRegionList = updatedRegionList;
                    setRegionList(newRegionList);
                    
                    localStorage.setItem(`nikeequipment${versionRegionList}`, JSON.stringify(newRegionList));
                  }}
                >
                  Nike {title}
                </h2>
                <motion.div
                  className={`${NikeEquipmentCSS["region-webhook"]}`}
                  animate={el.folded ? "open" : "closed"}
                  variants={variants}
                >
                  <div className={NikeEquipmentCSS["webhook"]}>
                    <h3 className={NikeEquipmentCSS["webhook-status"]}>ACTIVE Webhooks</h3>
                    {activeInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeEquipmentCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeEquipmentCSS["webhook-grid"]}>
                            <label className={NikeEquipmentCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeEquipmentCSS["webhook-row"]}>
                              <input
                                className={NikeEquipmentCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newActiveInputValue = { ...activeInputValue };
                                    const updatedWebhook = activeInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newActiveInputValue[el.region] = updatedWebhook;
                                    setActiveInputValue(newActiveInputValue);
                                  }
                                }}
                                disabled={activeInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeEquipmentCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newActiveInputValue = { ...activeInputValue };
                                  const updatedWebhook = activeInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newActiveInputValue[e.currentTarget.name] = updatedWebhook;
                                  setActiveInputValue(newActiveInputValue);
                                }}
                              ><img className={NikeEquipmentCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeEquipmentCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-ACTIVE-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeEquipmentCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-ACTIVE-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  <div className={NikeEquipmentCSS["webhook"]}>
                    <h3 className={NikeEquipmentCSS["webhook-status"]}>CLOSEOUT Webhooks</h3>
                    {closeoutInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeEquipmentCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeEquipmentCSS["webhook-grid"]}>
                            <label className={NikeEquipmentCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeEquipmentCSS["webhook-row"]}>
                              <input
                                className={NikeEquipmentCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newCloseoutInputValue = { ...closeoutInputValue };
                                    const updatedWebhook = closeoutInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newCloseoutInputValue[el.region] = updatedWebhook;
                                    setCloseoutInputValue(newCloseoutInputValue);
                                  }
                                }}
                                disabled={closeoutInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeEquipmentCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newCloseoutInputValue = { ...closeoutInputValue };
                                  const updatedWebhook = closeoutInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newCloseoutInputValue[e.currentTarget.name] = updatedWebhook;
                                  setCloseoutInputValue(newCloseoutInputValue);
                                }}
                              ><img className={NikeEquipmentCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeEquipmentCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-CLOSEOUT-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeEquipmentCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-CLOSEOUT-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  <div className={NikeEquipmentCSS["webhook"]}>
                    <h3 className={NikeEquipmentCSS["webhook-status"]}>HOLD Webhooks</h3>
                    {holdInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeEquipmentCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeEquipmentCSS["webhook-grid"]}>
                            <label className={NikeEquipmentCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeEquipmentCSS["webhook-row"]}>
                              <input
                                className={NikeEquipmentCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newHoldInputValue = { ...holdInputValue };
                                    const updatedWebhook = holdInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newHoldInputValue[el.region] = updatedWebhook;
                                    setHoldInputValue(newHoldInputValue);
                                  }
                                }}
                                disabled={holdInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeEquipmentCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newHoldInputValue = { ...holdInputValue };
                                  const updatedWebhook = holdInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newHoldInputValue[e.currentTarget.name] = updatedWebhook;
                                  setHoldInputValue(newHoldInputValue);
                                }}
                              ><img className={NikeEquipmentCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeEquipmentCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-HOLD-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeEquipmentCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-HOLD-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeEquipmentCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  
                  {el.region === 'US' ? 
                    <div className={NikeEquipmentCSS["webhook"]}>
                      <h3 className={NikeEquipmentCSS["webhook-status"]}>Discount Webhooks</h3>
                      {discountInputValue[el.region].map((webhook, index) => {
                        return (
                          <form className={NikeEquipmentCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                            <div className={NikeEquipmentCSS["webhook-grid"]}>
                              <label className={NikeEquipmentCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                              <div className={NikeEquipmentCSS["webhook-row"]}>
                                <input
                                  className={NikeEquipmentCSS["webhook-input"]}
                                  value={webhook.value}
                                  name={el.region}
                                  type="url"
                                  id={webhook.label}
                                  required 
                                  onChange={e => {
                                    if (e.target.name === el.region) {
                                      let newDiscountInputValue = { ...discountInputValue };
                                      const updatedWebhook = discountInputValue[el.region].map(el => {
                                        if (el.label === webhook.label) {
                                          return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                        } else {
                                          return el;
                                        }
                                      });
                                      newDiscountInputValue[el.region] = updatedWebhook;
                                      setDiscountInputValue(newDiscountInputValue);
                                    }
                                  }}
                                  disabled={discountInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                                />
                                <button className={NikeEquipmentCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                  onClick={e => {
                                    let newDiscountInputValue = { ...discountInputValue };
                                    const updatedWebhook = discountInputValue[e.currentTarget.name].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                      } else {
                                        return el;
                                      }
                                    });
                                    newDiscountInputValue[e.currentTarget.name] = updatedWebhook;
                                    setDiscountInputValue(newDiscountInputValue);
                                  }}
                                ><img className={NikeEquipmentCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                                <button className={NikeEquipmentCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-DISCOUNT-UPDATE"} type="submit"
                                  onClick={handleSubmit}
                                ><img className={NikeEquipmentCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                                <button className={NikeEquipmentCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-DISCOUNT-DELETE"} type="button"
                                  onClick={handleSubmit}
                                ><img className={NikeEquipmentCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                              </div>
                            </div>
                          </form>
                        );
                      })}
                    </div> : null}



                </motion.div>
              </div>
            )
          })} </> : <div className={NikeEquipmentCSS["access-spin"]}><span className={NikeEquipmentCSS["access-spinner"]}></span></div>}

        <div className={NikeEquipmentCSS["bottom-container"]}>
          <div className={NikeEquipmentCSS["menu-box"]}>
            <div onClick={() => setUploadMenu(!uploadMenu)} className={NikeEquipmentCSS["menu-btn"]}>
              <img className={NikeEquipmentCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${NikeEquipmentCSS["upload-container"]} ${uploadMenu ? NikeEquipmentCSS["upload-container-show"] : ""}`}>
            <h2 className={NikeEquipmentCSS["upload-form-title"]} htmlFor="region">Enter the new webhook</h2>
            
            <form className={NikeEquipmentCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewWebhookSubmit}>
              <input className={NikeEquipmentCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
              <input className={NikeEquipmentCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="url" name="webhook" placeholder="Webhook URL" required />
              
              <select className={NikeEquipmentCSS["upload-select-region"]} name="region">
                <option value="US">United States</option>
                <option value="JP">Japan</option>
                <option value="SG">Singapore</option>
                <option value="MY">Malaysia</option>
                <option value="TH">Thailand</option>
                <option value="IN">India</option>
                <option value="AU">Australia</option>
                <option value="CA">Canada</option>
                <option value="CN">China</option>
                <option value="FR">France</option>
                <option value="GB">United Kingdom</option>
                <option value="MX">Mexico</option>
                <option value="PH">Philippines</option>
                <option value="KR">Korea</option>
                <option value="TW">Taiwan</option>
                <option value="AT">Austria</option>
              </select>

              <select className={NikeEquipmentCSS["upload-select-status"]} name="status">
                <option value="ACTIVE">Active</option>
                <option value="CLOSEOUT">Closeout</option>
                <option value="HOLD">Hold</option>
                <option value="DISCOUNT">Discount</option>
              </select>

              <button className={NikeEquipmentCSS["upload-submit-btn"]} type="submit">Upload</button>
              <button className={NikeEquipmentCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
            </form>
          </div>
        </div>

        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeEquipmentCSS["second-background"]}></div> : <></>}
      </div>



  {/* {<form className={NikeEquipmentCSS["form-container"]} action="" method="post" id="ACTIVE" onSubmit={handleSubmit}>
      <label htmlFor="activeInputWebhook">Enter the ACTIVE Webhooks</label>
        <textarea
          className={NikeEquipmentCSS["webhook-textarea"]}
          type="text"
          id="activeInputWebhook"
          name="activeInputWebhook"
          value={activeInputValue}
          onChange={e => setActiveInputValue(e.target.value)}
          placeholder='[
            "EXAMPLE1",
            "EXAMPLE2"
]'
          required
        />
        <button className={isClicked[0] ? NikeEquipmentCSS["spin"] : ""} type="submit">
          {showInfo[0] === "sent" && <div className={NikeEquipmentCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Webhooks updated successfully</p></div>}
          {showInfo[0] === "error" && <div className={NikeEquipmentCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Update
          <span className={NikeEquipmentCSS["spinner"]}></span>
        </button>
      </form>
      <form className={NikeEquipmentCSS["form-container"]} action="" method="post" id="CLOSEOUT" onSubmit={handleSubmit}>
        <label htmlFor="closeoutInputWebhook">Enter the CLOSEOUT Webhooks</label>
        <textarea
          className={NikeEquipmentCSS["webhook-textarea"]}
          type="text"
          id="closeoutInputWebhook"
          name="closeoutInputWebhook"
          value={closeoutInputValue}
          onChange={e => setCloseoutInputValue(e.target.value)}
          placeholder='[
            "EXAMPLE1",
            "EXAMPLE2"
]'
          required
        />
        <button className={isClicked[1] ? NikeEquipmentCSS["spin"] : ""} type="submit">
          {showInfo[1] === "sent" && <div className={NikeEquipmentCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Webhooks updated successfully</p></div>}
          {showInfo[1] === "error" && <div className={NikeEquipmentCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Update
          <span className={NikeEquipmentCSS["spinner"]}></span>
        </button>
      </form>
      <form className={NikeEquipmentCSS["form-container"]} action="" method="post" id="HOLD" onSubmit={handleSubmit}>
        <label htmlFor="holdInputWebhook">Enter the HOLD Webhooks</label>
        <textarea
          className={NikeEquipmentCSS["webhook-textarea"]}
          type="text"
          id="holdInputWebhook"
          name="holdInputWebhook"
          value={holdInputValue}
          onChange={e => setHoldInputValue(e.target.value)}
          placeholder='[
            "EXAMPLE1",
            "EXAMPLE2"
]'
          required
        />
        <button className={isClicked[2] ? NikeEquipmentCSS["spin"] : ""} type="submit">
          {showInfo[2] === "sent" && <div className={NikeEquipmentCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Webhooks updated successfully</p></div>}
          {showInfo[2] === "error" && <div className={NikeEquipmentCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Update
          <span className={NikeEquipmentCSS["spinner"]}></span>
        </button>
      </form>} */}
    </div>
  );
}

export default NikeEquipment;