import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import AdidasCSS from "../css/adidas.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function Adidas() {
  // Region list: US
  const [hot1InputValue, setHot1InputValue] = useState({
    US: []
  });

  const versionRegionList = "v0.0.1";
  const [regionList, setRegionList] = useState([
    { region: "US", folded: false }
  ]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const status = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (status) {
      case "HOT1":
        data = hot1InputValue[region].find(el => el.label === label);
        break;
    
      default:
        break;
    }
    
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/adidaswebhooks?region=${region}&category=HOT1`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          status,
          data: {label: data.label, value: data.value}
        }
      };

      axios(config).then((res) => {
        setReloadWebhooks(!reloadWebhooks);
      }).catch(() => { });
    } catch (error) { }
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/adidaswebhooks?region=${region}&category=HOT1`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status,
          data: {label: label, value: webhookURL},
        },
      };

      axios(config).then(() => {
        setLabelInputValue("");
        setWebhookURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);
      }).catch(() => { });
    } catch (error) { console.log(error)}
  };

  useEffect(() => {
    setShowWebhooks([]);
    
    const newHot1InputValue = { ...hot1InputValue };

    regionList.forEach((el, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/adidaswebhooks?region=${el.region}&category=hot1`,
        };

        axios(config)
          .then((res) => {
            newHot1InputValue[el.region] = res.data[0].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });

            setHot1InputValue(newHot1InputValue);
          }).catch(() => {}).finally(() => setShowWebhooks(prevState => [...prevState, 1]));
      }, index * 100);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  // SKU Section
  const [inputSKU, setInputSKU] = useState({
    adidashot1: ""
  });

  const [isSubmitClicked, setIsSubmitClicked] = useState({
    adidashot1: false
  });
  const [isDeleteClicked, setIsDeleteClicked] = useState({
    adidashot1: false
  });
  
  const [showInfo, setShowInfo] = useState({
    adidashot1: ""
  });
  const [showRemoveInfo, setShowRemoveInfo] = useState({
    adidashot1: ""
  });

  const handleSKUSubmit = async e => {
    e.preventDefault();

    const type = e.target.name;
    let sku = type === "adidashot1" ? inputSKU.adidashot1 : "null";
    
    if (type === 'adidashot1') setIsSubmitClicked({ adidashot1: true });

    if (sku.includes(' ')) {
      sku = (sku.split(' ')).filter(_ => _ !== '');
    } else {
      sku = [sku];
    }

    setTimeout(() => {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/adidasskus?action=ADD',
          data: {
            sku: sku,
            type: type.toUpperCase()
          }
        };
  
        axios(config)
          .then(() => {
            if (type === 'adidashot1') setShowInfo({ adidashot1: "sent" });
          })
          .catch(() => {
            if (type === 'adidashot1') setShowInfo({ adidashot1: "error" });
          });
      } catch (error) {
        if (type === 'adidashot1') setShowInfo({ adidashot1: "error" });
      }

      if (type === 'adidashot1') setIsSubmitClicked({ adidashot1: false });
    }, 1000);
  };

  const handleSKUDelete = e => {
    e.preventDefault();

    const type = e.target.name;
    let sku = type === "adidashot1" ? inputSKU.adidashot1 : "null";

    if (type === 'adidashot1') setIsDeleteClicked({ adidashot1: true });

    if (sku.includes(' ')) {
      sku = (sku.split(' ')).filter(_ => _ !== '');
    } else {
      sku = [sku];
    }

    setTimeout(() => {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/adidasskus?action=REMOVE',
          data: {
            sku: sku,
            type: type.toUpperCase()
          }
        };
  
        axios(config)
          .then(() => {
            if (type === 'adidashot1') setShowRemoveInfo({ adidashot1: "sent" });
          })
          .catch(() => {
            if (type === 'adidashot1') setShowRemoveInfo({ adidashot1: "error" });
          });
      } catch (error) {
        if (type === 'adidashot1') setShowRemoveInfo({ adidashot1: "error" });
      }

      if (type === 'adidashot1') setIsDeleteClicked({ adidashot1: false });
    }, 1000);
  };

  useEffect(() => {
    setTimeout(async () => {
      setShowInfo({ adidashot1: "" });
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    setTimeout(async () => {
      setShowRemoveInfo({ adidashot1: "" });
    }, 8000);
  }, [showRemoveInfo]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  }, [uploadMenu]);

  const variants = {
    open: { opacity: 1, x: 0, display: "flex" },
    closed: { opacity: 0, x: "0%", display: "none" },
  }

  useEffect(() => {
    const storedRegionList = JSON.parse(localStorage.getItem(`adidas${versionRegionList}`)) || [];

    if (!storedRegionList.length) {
      localStorage.setItem(`adidas${versionRegionList}`, JSON.stringify(regionList))
    } else {
      setRegionList(storedRegionList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={AdidasCSS["root-adidas"]}>
      <div className={AdidasCSS["region-container"]}>
        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={AdidasCSS["first-background"]}></div> : <></>}

        {showWebhooks.length === regionList.length ? <>
          {regionList.map((el, index) => {
            let title = 'unknown'
            switch (el.region) {
              case "US":
                title = "United States";
                break;
              default:
                break;
            }

            return (
              <div className={AdidasCSS["region-box"]} key={index}>
                <form className={AdidasCSS["form-container"]} onSubmit={handleSKUSubmit} method="post" name="adidashot1">
                  <label htmlFor="inputName">Enter the SKU (Adidas Hot #1)</label>
                  <input type="text" name="inputAdidasHot1SKU" id="inputAdidasHot1SKU" onChange={e => setInputSKU({ adidashot1: e.target.value })}/>
                  <button className={isSubmitClicked.adidashot1 ? AdidasCSS["spin"] : ""} type="submit">
                    {showInfo.adidashot1 === "sent" && <div className={AdidasCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
                    {showInfo.adidashot1 === "error" && <div className={AdidasCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
                    Add
                    <span className={AdidasCSS["spinner"]}></span>
                  </button>
                  <button 
                    className={isDeleteClicked.adidashot1 ? AdidasCSS["spin"] : ""}
                    onClick={handleSKUDelete} 
                    type="button" 
                    name="adidashot1">
                    {showRemoveInfo.adidashot1 === "sent" && <div className={AdidasCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
                    {showRemoveInfo.adidashot1 === "error" && <div className={AdidasCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
                    Remove
                  </button>
                </form>

                <h2
                  className={`${AdidasCSS["region-title"]} ${el.folded ? AdidasCSS["region-title-hot1"] : ""}`}
                  onClick={() => {
                    let newRegionList = { ...regionList };
                    const updatedRegionList = regionList.map(e => {
                      if (e.region === el.region) {
                        return { region: el.region, folded: !e.folded };
                      } else {
                        return e;
                      }
                    });
                    newRegionList = updatedRegionList;
                    setRegionList(newRegionList);
                    
                    localStorage.setItem(`adidas${versionRegionList}`, JSON.stringify(newRegionList));
                  }}
                >
                  Adidas {title}
                </h2>

                <motion.div
                  className={`${AdidasCSS["region-webhook"]}`}
                  animate={el.folded ? "open" : "closed"}
                  variants={variants}
                >
                  <div className={AdidasCSS["webhook"]}>
                    <h3 className={AdidasCSS["webhook-status"]}>HOT 1 Webhooks</h3>
                    {hot1InputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={AdidasCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={AdidasCSS["webhook-grid"]}>
                            <label className={AdidasCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={AdidasCSS["webhook-row"]}>
                              <input
                                className={AdidasCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newHot1InputValue = { ...hot1InputValue };
                                    const updatedWebhook = hot1InputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newHot1InputValue[el.region] = updatedWebhook;
                                    setHot1InputValue(newHot1InputValue);
                                  }
                                }}
                                disabled={hot1InputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={AdidasCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newHot1InputValue = { ...hot1InputValue };
                                  const updatedWebhook = hot1InputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newHot1InputValue[e.currentTarget.name] = updatedWebhook;
                                  setHot1InputValue(newHot1InputValue);
                                }}
                              ><img className={AdidasCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={AdidasCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-HOT1-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={AdidasCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={AdidasCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-HOT1-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={AdidasCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>
                </motion.div>
              </div>
            )
          })} </> : <div className={AdidasCSS["access-spin"]}><span className={AdidasCSS["access-spinner"]}></span></div>}

        <div className={AdidasCSS["bottom-container"]}>
          <div className={AdidasCSS["menu-box"]}>
            <div onClick={() => setUploadMenu(!uploadMenu)} className={AdidasCSS["menu-btn"]}>
              <img className={AdidasCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${AdidasCSS["upload-container"]} ${uploadMenu ? AdidasCSS["upload-container-show"] : ""}`}>
            <h2 className={AdidasCSS["upload-form-title"]} htmlFor="region">Enter the new webhook</h2>
            
            <form className={AdidasCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewWebhookSubmit}>
              <input className={AdidasCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
              <input className={AdidasCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="url" name="webhook" placeholder="Webhook URL" required />
              
              <select className={AdidasCSS["upload-select-region"]} name="region">
                <option value="US">United States</option>
              </select>

              <select className={AdidasCSS["upload-select-status"]} name="status">
                <option value="HOT1">HOT 1</option>
              </select>

              <button className={AdidasCSS["upload-submit-btn"]} type="submit">Upload</button>
              <button className={AdidasCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
            </form>
          </div>
        </div>

        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={AdidasCSS["second-background"]}></div> : <></>}
      </div>
    </div>
  );
}

export default Adidas;
