import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ContentCSS from "../css/content.module.css";
import Skus from "./Skus";
import Webhooks from "./Webhooks";
import Access from "./Access";
import NikeEquipment from "./NikeEquipment";
import NikeShoes from "./NikeShoes";
import NikeShoesRefurbished from "./NikeShoesRefurbished";
import HP from "./HP";
import StockNumber from "./StockNumber";
import ImageMonitor from "./ImageMonitor";
import ProductQuery from "./ProductQuery";
import Adidas from "./Adidas";
import Twitter from "./Twitter";
import ASICS from "./ASICS";
import URLShortener from "./URLShortener";

function Content() {
  const params = useParams();

  const renderContent = () => {
    let content;

    switch (params.contentId) {
      case "skus":
        content = <Skus />;
        break;
      case "webhooks":
        content = <Webhooks />;
        break;
      case "access":
        content = <Access />;
        break;
      case "nike-equipment":
        content = <NikeEquipment />;
        break;
      case "nike-shoes":
        content = <NikeShoes />;
        break;
      case "nike-refurbished":
        content = <NikeShoesRefurbished />;
        break;
      case "hp":
        content = <HP />;
        break;
      case "stocknumber":
        content = <StockNumber />;
        break;
      case "imagemonitor":
        content = <ImageMonitor />;
        break;
      case "productquery":
        content = <ProductQuery />;
        break;
      case "adidas":
        content = <Adidas />;
        break;
      case "twitter":
        content = <Twitter />;
        break;
      case "asics":
        content = <ASICS />;
        break;
      case "urlshortener":
        content = <URLShortener />;
        break;

      default:
        content = null;
        break;
    }

    return content;
  };

  useEffect(() => {
    document.body.style.overflow = "";
  });

  return <div className={ContentCSS["content"]}>{renderContent()}</div>;
}

export default Content;
