import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import UserPortal from "./components/User Portal/UserPortal";
import Dashboard from "./components/Admin Dashboard/Dashboard";
import Client from "./components/Client Dashboard/Client";
import Settings from "./components/Settings/Settings";
import NoMatch from "./components/No Match/NoMatch";
import PrivacyPolicyAndToS from "./components/Privacy Policy and ToS/PrivacyPolicyAndToS";
import "./index.css"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="userportal/accesstoken" element={<UserPortal />} />
        <Route path="dashboard/admin/*" element={<Dashboard />} />
        <Route path="dashboard/settings/*" element={<Settings />} />
        <Route path="dashboard/client/*" element={<Client />} />
        <Route path="privacy-policy-and-terms/*" element={<PrivacyPolicyAndToS />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;