import { useState, useEffect } from "react";
import axios from "axios";
import SkusCSS from "../css/skus.module.css";
import searchImg from "../assets/search.svg";
import downloadImg from "../assets/download.svg";
import AOS from "aos";
import "aos/dist/aos.css";

function Skus() {
  const [inputSKUSearchValue, setInputSKUSearchValue] = useState("");
  const [colorValue, setColorValue] = useState("#ffffff");
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [isColorPickerActive, setIsColorPickerActive] = useState(false);

  const [skuImages, setSKUImages] = useState([]);
  const [skuImagesMirror2, setSKUImagesMirror2] = useState([]);
  const [skuImagesMirror3, setSKUImagesMirror3] = useState([]);
  const [skuImagesMirror4, setSKUImagesMirror4] = useState([]);

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);

  const [isSearchClicked, setIsSearchClicked] = useState(false);
  
  const [showInfo, setShowInfo] = useState("");
  const [showRemoveInfo, setShowRemoveInfo] = useState("");

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   setIsSubmitClicked(true);

  //   setTimeout(() => {
  //     try {
  //       const config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: '/api/skus?action=ADD',
  //         data: {
  //           sku: inputSKU
  //         }
  //       };
  
  //       axios(config)
  //         .then(() => setShowInfo("sent"))
  //         .catch(() => setShowInfo("error"));
  //     } catch (error) { setShowInfo("error"); }
  //     setIsSubmitClicked(false);
  //   }, 1000);
  // };

  const handleSKUSearchImg = e => {
    e.preventDefault();
    setIsSearchClicked(true);

    setSKUImages([]);
    setSKUImagesMirror2([]);
    setSKUImagesMirror3([]);
    setSKUImagesMirror4([]);

    try {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/api/skuimage',
        headers: { 
          'Content-Type': 'application/json'
        },
        params: {
          sku: inputSKUSearchValue,
          type: "admin",
          color: colorValue,
          transparent: checkBoxState
        }
      };
      
      axios(config)
        .then((res) => {
          // if (e.target.name === "mirror#1") {
          //   setSKUImages(res.data.data);
          //   setIsSearchClicked(false);
          // } else if (e.target.name === "mirror#2") {
          //   console.log(res.data);
          //   const filtered_image_urls = res.data.data.filter(el => el !== 'undefined');
          //   const image_urls = filtered_image_urls.map(el => el.replace(/\/t_default\/u_[a-f0-9-]+,c_scale,fl_relative,w_1\.0,h_1\.0,fl_layer_apply/, ''));

          //   setSKUImagesMirror2(image_urls);
          //   setIsSearchClickedMirror2(false);
          // } else if (e.target.name === "mirror#3") {
          //   setSKUImagesMirror3(res.data.data);
          //   setIsSearchClickedMirror3(false);
          // }
          try {
            if (res.data.data === "null") return setIsSearchClicked(false);

            setIsSearchClicked(false);

            // const mirror1 = res.data.data.mirror1.map(el => {
            //   return (Buffer(el.data, 'base64'));
            // });

            // console.log(mirror1);

            setSKUImages(res.data.data.mirror1);
            
            // const filtered_image_urls = res.data.data.mirror2.filter(el => el !== 'undefined');
            // const image_urls = filtered_image_urls.map(el => el.replace(/\/t_default\/u_[a-f0-9-]+,c_scale,fl_relative,w_1\.0,h_1\.0,fl_layer_apply/, ''));
            // setSKUImagesMirror2(image_urls);

            setSKUImagesMirror2(res.data.data.mirror2);
            
            setSKUImagesMirror3(res.data.data.mirror3);
            setSKUImagesMirror4(res.data.data.mirror4);
          } catch (error) { console.log(error); }
        }).catch(() => { setIsSearchClicked(false); });
    } catch (err) { }
  };

  const handleDownload = async e => {
    const imageUrl = e.currentTarget.id;
    const [mirror, index] = e.currentTarget.name.split("-");
    const fileExtension = mirror === "mirror2" ? "png" : "png";

    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        throw new Error('Image not found');
      }

      const imageBlob = await response.blob();

      const customFileName = window.prompt('Enter file name:', `image_${mirror}_${index}`);
      if (customFileName === null) return;

      const downloadLink = document.createElement('a');
      const imageUrlObjectURL = URL.createObjectURL(imageBlob);

      downloadLink.setAttribute('download', `${customFileName}.${fileExtension}`);
      downloadLink.href = imageUrlObjectURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(imageUrlObjectURL);
      document.body.removeChild(downloadLink);
    } catch (err) { }
  };

  // const handleDownload = async (e) => {
  //   const fileExtension = e.currentTarget.name === "mirror2" ? "png" : "jpg";
  //   let a = document.createElement("a");
  //   a.href = "data:image/png;base64," + e.currentTarget.id;
  //   a.download = "image."+ fileExtension;
  //   a.click();
  // };

  useEffect(() => {
    setTimeout(() => {
      setShowInfo("");
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    setTimeout(() => {
      setShowRemoveInfo("");
    }, 8000);
  }, [showRemoveInfo]);

  useEffect(() => {
    AOS.init({      
      offset: 300,
      duration: 100,
      easing: 'ease-in-out-quart',
    });
  }, []);

  const [inputSKU, setInputSKU] = useState("");

  const handleDelete = e => {
    e.preventDefault();
    setIsDeleteClicked(true);

    setTimeout(() => {
      try {
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/skus?action=REMOVE',
          data: {
            sku: inputSKU
          }
        };
  
        axios(config)
          .then(() => setShowRemoveInfo("sent"))
          .catch(() => setShowRemoveInfo("error"));
      } catch (error) { setShowRemoveInfo("error"); }
      setIsDeleteClicked(false);
    }, 1000);
  };

  useEffect(() => {
    const mirror1 = skuImages.map(el => {
      if (el.includes("&bgc=")) {
        return (`${el.slice(0, el.length - 11)}&bgc=${colorValue.slice(1)}`);
      } else if (el.includes('&fmt=png-alpha')) {
        return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&bgc=${colorValue.slice(1)}`);
      }
    });
    const mirror3 = skuImagesMirror3.map(el => {
      if (el.includes("&bgc=")) {
        return (`${el.slice(0, el.length - 11)}&bgc=${colorValue.slice(1)}`);
      } else if (el.includes('&fmt=png-alpha')) {
        return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&bgc=${colorValue.slice(1)}`);
      }
    });
    const mirror4 = skuImagesMirror4.map(el => {
      if (el.includes("&bgc=")) {
        return (`${el.slice(0, el.length - 11)}&bgc=${colorValue.slice(1)}`);
      } else if (el.includes('&fmt=png-alpha')) {
        return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&bgc=${colorValue.slice(1)}`);
      }
    });
    
    setSKUImages(mirror1);
    setSKUImagesMirror3(mirror3);
    setSKUImagesMirror4(mirror4);

    setCheckBoxState(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorValue]);

  useEffect(() => {
    const mirror1 = skuImages.map(el => {
      if (!checkBoxState) {
        return el.slice(0, el.length - 6) + colorValue.slice(1)
        // return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&fmt=png-alpha`);
      }
    });
    const mirror3 = skuImagesMirror3.map(el => {
      if (!checkBoxState) {
        return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&fmt=png-alpha`);
      }
    });
    const mirror4 = skuImagesMirror4.map(el => {
      if (!checkBoxState) {
        return (`${el.slice(0, el.length - 14)}&bgc=${colorValue.slice(1)}`);
      } else {
        return (`${el}&fmt=png-alpha`);
      }
    });
    
    setSKUImages(mirror1);
    setSKUImagesMirror3(mirror3);
    setSKUImagesMirror4(mirror4);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkBoxState]);

  return (
    <div className={SkusCSS["skus"]}>
      {/* <form className={SkusCSS["form-container"]} onSubmit={handleSubmit} method="post">
        <label htmlFor="inputName">Enter the SKU</label>
        <input type="text" name="inputSKU" id="inputSKU" onChange={e => setInputSKU(e.target.value)}/>
        <button className={isSubmitClicked ? SkusCSS["spin"] : ""} type="submit">
          {showInfo === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU added successfully</p></div>}
          {showInfo === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Add
          <span className={SkusCSS["spinner"]}></span>
        </button>
        <button 
          className={isDeleteClicked ? SkusCSS["spin"] : ""}
          onClick={handleDelete} 
          type="button" 
          name="remove">
          {showRemoveInfo === "sent" && <div className={SkusCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>SKU deleted successfully</p></div>}
          {showRemoveInfo === "error" && <div className={SkusCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Remove
        </button>
      </form> */}

      <h2 className={SkusCSS["sku-search-title"]}>Search SKU Images</h2>
      <form onSubmit={handleSKUSearchImg} action="" method="post" className={SkusCSS["search-container"]}>
        <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValue} onChange={e => setInputSKUSearchValue(e.target.value)}/>
        <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="search"/>
      </form>
      <div className={SkusCSS["sku-customize-container"]}>
        <input className={SkusCSS["sku-search-color"]} value={colorValue} onChange={(e) => setColorValue(e.target.value)} type="color" name="" id="" />
        <input className={SkusCSS["sku-search-checkbox"]} checked={checkBoxState} onChange={() => {setCheckBoxState(!checkBoxState)}} type="checkbox" name="" id="" />
      </div>
      <div className={isSearchClicked ? SkusCSS["search-spin"] : ""}>
        <span className={SkusCSS["search-spinner"]}></span>
      </div>

      <div className={SkusCSS["search-sku-imgs"]}>
        <h2 className={SkusCSS["sku-search-title"]}>Mirror #1</h2>
        {/* <div className={SkusCSS["search-container"]}>
          <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValue} onChange={e => setInputSKUSearchValue(e.target.value)}/>
          <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#1"/>
        </div> */}

        <div className={SkusCSS["sku-image-container"]}>
          {skuImages.length ? (
            skuImages.map((img, index) => {
              return (
                <div key={index} className={SkusCSS["sku-image-box"]}>
                  <img className={SkusCSS["sku-img"]} src={img} alt={img} />
                  {/* <img className={SkusCSS["sku-img"]} src={`data:image/jpeg;base64,${img}`} alt={`img-${index}`} /> */}

                  <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror1-${index}`} alt="download-img" id={img}/>
                </div>
              )
            })
          ) : <div></div>}
        </div>
      </div>

      <div className={SkusCSS["search-sku-imgs"]}>
        <h2 className={SkusCSS["sku-search-title"]}>Mirror #2</h2>
        {/* <div className={SkusCSS["search-container"]}>
          <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValueMirror2} onChange={e => setInputSKUSearchValueMirror2(e.target.value)}/>
          <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#2"/>
        </div> */}

        <div className={SkusCSS["sku-image-container"]}>
          {skuImagesMirror2.length ? (
            skuImagesMirror2.map((img, index) => {
              return (
                <div key={index} className={SkusCSS["sku-image-box"]}>
                  <img className={SkusCSS["sku-img"]} src={img} alt={img} />
                  {/* <img className={SkusCSS["sku-img"]} src={`data:image/png;base64,${img}`} alt={`img-${index}`} /> */}
                  
                  <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror2-${index}`} alt="download-img" id={img}/>
                </div>
              )
            })
          ) : <div></div>}
        </div>
      </div>

      <div className={SkusCSS["search-sku-imgs"]}>
        <h2 className={SkusCSS["sku-search-title"]}>Mirror #3</h2>
        {/* <div className={SkusCSS["search-container"]}>
          <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValueMirror3} onChange={e => setInputSKUSearchValueMirror3(e.target.value)}/>
          <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#3"/>
        </div> */}

        <div className={SkusCSS["sku-image-container"]}>
          {skuImagesMirror3.length ? (
            skuImagesMirror3.map((img, index) => {
              return (
                <div key={index} className={SkusCSS["sku-image-box"]}>
                  <img className={SkusCSS["sku-img"]} src={img} alt={img} />
                  {/* <img className={SkusCSS["sku-img"]} src={`data:image/jpeg;base64,${img}`} alt={`img-${index}`} /> */}
                  
                  <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror3-${index}`} alt="download-img" id={img}/>
                </div>
              )
            })
          ) : <div></div>}
        </div>
      </div>

      <div className={SkusCSS["search-sku-imgs"]}>
        <h2 className={SkusCSS["sku-search-title"]}>Mirror #4</h2>
        {/* <div className={SkusCSS["search-container"]}>
          <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValueMirror4} onChange={e => setInputSKUSearchValueMirror4(e.target.value)}/>
          <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#4"/>
        </div> */}

        <div className={SkusCSS["sku-image-container"]}>
          {skuImagesMirror4.length ? (
            skuImagesMirror4.map((img, index) => {
              return (
                <div key={index} className={SkusCSS["sku-image-box"]}>
                  <img className={SkusCSS["sku-img"]} src={img} alt={img} />
                  {/* <img className={SkusCSS["sku-img"]} src={`data:image/jpeg;base64,${img}`} alt={`img-${index}`} /> */}
                  
                  <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror4-${index}`} alt="download-img" id={img}/>
                </div>
              )
            })
          ) : <div></div>}
        </div>
      </div>
    </div>
  );
}

export default Skus;