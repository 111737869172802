import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/logo.webp";
import "../css/Default Styles/navigation.css";
import "../css/Desktop Styles/navigation.css";
import "../css/Tablet Styles/navigation.css";
import "../css/Mobile Styles/navigation.css";

function Navigation() {
  const navigate = useNavigate();

  return (
    <div className="navigation">
      <div className="left-header">
        <img src={logo} alt="logo" className="logo" />
        <a tabIndex="-1" className="home-link" href="#home">Home</a>
        <a tabIndex="-1" className="features-link" href="#features">Features</a>
        <a tabIndex="-1" className="faq-link" href="#faq">FAQ</a>
        <Link className="user-portal-link" to="/userportal/accesstoken">User Portal</Link>
      </div>
      
      <div className="right-header">
        <button onClick={() => navigate("/dashboard/admin")} tabIndex="-1" className="dashboard-btn">Client Dashboard</button>
      </div>
    </div>
  );
}

export default Navigation;