import twitterIcon from "../assets/logo-twitter.svg";
import discordIcon from "../assets/discord-blue.svg";
import "../css/Default Styles/footer.css";
import "../css/Desktop Styles/footer.css";
import "../css/Tablet Styles/footer.css";
import "../css/Mobile Styles/footer.css";

function Footer() {
  return (
    <div className="footer">
      <h1><span>Elden</span> Monitors</h1>
      <p>Revolutionizing the monitoring industry one ping at a time</p>
      <p><a href="https://eldenmonitors.com/privacy-policy-and-terms" target="_blank" rel="noopener noreferrer">Privacy Policy and Terms of Service</a> </p>
      {/* <p>Contact us <a className="footer-mail" href="mailto:admin@tryaio.com ">admin@tryaio.com </a></p> */}
      <div className="social-links-container">
        <a tabIndex="-1" href="https://twitter.com/eldenmonitors/"><img src={twitterIcon} alt="twitter-logo" /></a>
        <a tabIndex="-1" href="https://discord.gg/g3W3WHpwus"><img src={discordIcon} alt="discord-logo" /></a>
      </div>
    </div>
  );
}

export default Footer;