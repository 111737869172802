import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateChannel } from "../store/channelSlice";
import axios from "axios";
import ChannelTabCSS from "../css/channeltab.module.css";
import hashtagImg from "../assets/hashtag.svg";
import deleteImg from "../assets/delete.svg";
import arrowImg from "../assets/arrow.svg";
import arrowHoverImg from "../assets/arrow-hover.svg";
import arrowActiveImg from "../assets/arrow-active.svg";

function Channels() {
  const [channels, setChannels] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [allowedChannels, setAllowedChannels] = useState([]);
  const [showOptionContainer, setShowOptionContainer] = useState([]);
  const [showRegionContainer, setShowRegionContainer] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  const guild = useSelector(store => store.guild);
  const params = Object.values(useParams())[0];

  const handleClick = event => {
    const region = event.target.parentNode.parentNode.id;
    const channelId = event.target.parentNode.id;

    const data = channels.find(data => data.region === region);
    const channelArray = data.toolsNameList.find(channel => String(channel.id) === channelId);

    dispatch(updateChannel({
      channelRegion: data.region,
      channelId: channelArray.id,
      channelName: channelArray.toolsName
    }));
  };

  const handleDeleteClick = event => {
    event.preventDefault();

    axios.post(`/api/channeldelete`, {
      "id": event.currentTarget.id
    }).then(() => {}).catch(() => {});

    setTimeout(() => {
      setRefresh(!refresh);
    }, 1000);
  };

  const handleOption = event => {
    event.preventDefault();

    const region = event.currentTarget.id;
    const toolsName = event.currentTarget.textContent;

    axios.post(`/api/channelinsert`, {
      "guildId": guild.guildId,
      "region": region.toUpperCase(),
      "toolsName": toolsName,
    }).then(() => {}).catch(() => {});

    setTimeout(() => {
      const newInputValueArr = inputValue.map(item => {
        if (item.region === region) {
          return {region: item.region, value: ""};
        } else {
          return item;
        }
      });

      setInputValue(newInputValueArr);
      setRefresh(!refresh);
    }, 1000);
  };

  const toggleDropdown = event => {
    const region = event.currentTarget.id;
    
    const newArr = showOptionContainer.map(item => {
      if (item.region === region) {
        return {region: item.region, value: !item.value};
      } else {
        return {region: item.region, value: false};
      }
    });

    setShowOptionContainer(newArr);
  };

  const toggleRegion = event => {
    console.log(event.currentTarget.innerText);
    console.log(showRegionContainer);

    const newArr = showRegionContainer.map(item => {
      if (item.region === event.currentTarget.innerText) {
        return {region: item.region, value: !item.value, hover: item.hover, active: item.active};
      } else {
        return item;
      }
    });

    setShowRegionContainer(newArr);
  };

  useEffect(() => {
    channels.forEach(data => {
      const channelArray = data.toolsNameList.find(channel => String(channel.id) === params);
      const region = data.region;

      if(channelArray !== undefined) {
        dispatch(updateChannel({
          channelRegion: region,
          channelId: channelArray.id,
          channelName: channelArray.toolsName
        }));
      };
    });
  }, [dispatch, channels, params]);

  useEffect(() => {
    axios.get(`/api/channellist?guildId=${guild.guildId}`)
      .then(result => {
        setInputValue([]);
        setShowRegionContainer([]);

        result.data.data.forEach(item => {
          setInputValue(prevState => [...prevState, {region: item.region, value: ""}]);
          setShowRegionContainer(prevState => [...prevState, {region: item.region, value: false, hover: false, active: false}]);
        });

        setChannels([...result.data.data.reverse((a, b) => a.region - b.region)]);
      }).catch(() => {
        console.log('CHANNEL LIST run');
        // window.location.replace("/");
      });
  }, [guild, refresh]);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWindowClick = event => {
    if (event.target.name !== "option" && event.target.id !== "option" ) {
      const newArr = showOptionContainer.map(item => {
        if (item.value === true) {
          return {region: item.region, value: false};
        } else {
          return item;
        }
      });
  
      setShowOptionContainer(newArr);
    } 
  };

  useEffect(() => {
    setAllowedChannels([]);
    setShowOptionContainer([]);

    channels.forEach(data => {
      axios.get(`/api/toolsnamelist?guildId=${guild.guildId}&region=${data.region}`)
        .then(result => {
          setAllowedChannels(prevState => [...prevState, {region: data.region, toolsName: [...result.data.data]}]);
          setShowOptionContainer(prevState => [...prevState, {region: data.region, value: false}]);
        }).catch(() => {
          console.log("CHANNEL LIST run 2");
          // window.location.replace("/")
        });
    });
  }, [channels, guild]);

  useEffect(() => {
    window.addEventListener("click", handleWindowClick);
    
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [handleWindowClick]);

  const regionHoverToggle = event => {
    const newArr = showRegionContainer.map(item => {
      if (item.region === event.currentTarget.innerText) {
        return {region: item.region, value: item.value, hover: !item.hover, active: item.active};
      } else {
        return item;
      }
    });

    setShowRegionContainer(newArr);
  };

  useEffect(() => {
    console.log(showRegionContainer);
  }, [showRegionContainer]);

  const regionActiveToggle = event => {
    console.log('a');
  };

  return (
    <div className={ChannelTabCSS["channel-tab-list"]}>
      {/* Pre load images */}
      <img src={arrowHoverImg} alt="arrow-hover" style={{display: "none"}}/>
      <img src={arrowActiveImg} alt="arrow-active" style={{display: "none"}}/>

      <div className={ChannelTabCSS["channel-tab-top"]}>
        <h5 className={ChannelTabCSS["channel-tab-title"]}>
          {guild.guildName}
        </h5>
      </div>

      <div className={ChannelTabCSS["channel-tab-bottom"]}>
        {channels ? channels.map((data, index) => {
          return (
            <div id={data.region} className={ChannelTabCSS["region"]} key={index}>
              <h4 onClick={event => {toggleRegion(event); regionActiveToggle(event);}} className={ChannelTabCSS["region-title"]} onMouseEnter={regionHoverToggle} onMouseLeave={regionHoverToggle}>
                <img
                  className={`${ChannelTabCSS["region-title-arrow-img"]} ${showRegionContainer.filter(item => {
                    return item.region === data.region;
                  })[0].value ? ChannelTabCSS["region-title-arrow-down-img"] : ""}`}
                  src={`${showRegionContainer.filter(item => {
                    return item.region === data.region;
                  })[0].hover ? arrowHoverImg : arrowImg}`}
                  alt="arrow-hover-active"
                />
                {data.region}
              </h4>

              {data.toolsNameList.map((channel, index) => {
                return (
                  <div key={index} id={channel.id} className={`${ChannelTabCSS["channel"]} ${showRegionContainer.filter(item => {
                return item.region === data.region;
              })[0].value ? ChannelTabCSS["channel-hide"] : ""}`}>
                    <NavLink
                      onClick={handleClick}
                      to={String(channel.id)}
                      id={channel.id}
                      className={ChannelTabCSS["channel-navlink"]}
                      style={({isActive}) => ({
                        backgroundColor: isActive ? "#484848" : ""
                      })}
                    >
                      <img className={ChannelTabCSS["channel-hashtag-img"]} src={hashtagImg} alt="hasthat-img" />
                      {channel.toolsName}
                    </NavLink>
                    <button className={ChannelTabCSS["channel-delete-button"]} onClick={handleDeleteClick} id={channel.id} type="button"><img className={ChannelTabCSS["channel-delete-img"]} src={deleteImg} alt="save-img" /></button>
                  </div>
                )
              })}
              
              {showOptionContainer.length === channels.length ?
              <div className={`${ChannelTabCSS["channel-menu-container"]} ${showRegionContainer.filter(item => {
                return item.region === data.region;
              })[0].value ? ChannelTabCSS["channel-menu-container-hide"] : null}`}>
                <button onClick={toggleDropdown} id={data.region} value={index} name="option">Select a channel</button>
                <div className={`${ChannelTabCSS["channel-option-container"]} ${showOptionContainer.filter(item => {
                  return item.region === data.region;
                })[0].value ? ChannelTabCSS["channel-option-container-show"] : ""}`} id="option">
                  {allowedChannels.length ?
                    allowedChannels.map((toolsNameData, index) => {
                      return (
                        <div key={index}>
                          {toolsNameData.region === data.region ?
                            toolsNameData.toolsName.map((toolsName, index) => {
                              return (<div key={index} id={toolsNameData.region} onClick={handleOption} value={toolsName}><p id="option">{toolsName}</p></div>);
                            })
                          : <></>}
                        </div>
                      )
                    })
                  : <div value="not-found"><p>Not found</p></div>}
                </div>
              </div> : <></> }
            </div>
          )
        })
        : <></>}
      </div>
    </div>
  );
}

export default Channels;