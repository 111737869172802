import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import NikeShoesCSS from "../css/nikeShoes.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";
// import successImg from "../assets/success.svg";
// import errorImg from "../assets/error.svg";

function NikeShoes() {
  // Region list: US, JP, SG, MY, TH, IN, AU, CA, CN, FR, GB, MX, PH, KR, TW AT
  const [activeInputValue, setActiveInputValue] = useState({
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: [],
    US: []
  });
  const [closeoutInputValue, setCloseoutInputValue] = useState({
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: [],
    US: []
  });
  const [holdInputValue, setHoldInputValue] = useState({
    JP: [],
    SG: [],
    MY: [],
    TH: [],
    IN: [],
    AU: [],
    CA: [],
    CN: [],
    FR: [],
    GB: [],
    MX: [],
    PH: [],
    KR: [],
    TW: [],
    AT: [],
    US: []
  });
  const [discountInputValue, setDiscountInputValue] = useState({
    US: []
  });

  const versionRegionList = "v0.0.1";
  const [regionList, setRegionList] = useState([
    { region: "US", folded: false }, 
    { region: "JP", folded: false }, 
    { region: "SG", folded: false }, 
    { region: "MY", folded: false }, 
    { region: "TH", folded: false }, 
    { region: "IN", folded: false },
    { region: "AU", folded: false },
    { region: "CA", folded: false },
    { region: "CN", folded: false },
    { region: "FR", folded: false },
    { region: "GB", folded: false },
    { region: "MX", folded: false },
    { region: "PH", folded: false },
    { region: "KR", folded: false },
    { region: "TW", folded: false },
    { region: "AT", folded: false }
  ]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const status = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (status) {
      case "ACTIVE":
        data = activeInputValue[region].find(el => el.label === label);
        break;
      case "CLOSEOUT":
        data = closeoutInputValue[region].find(el => el.label === label);
        break;
      case "HOLD":
        data = holdInputValue[region].find(el => el.label === label);
        break;
      case "DISCOUNT":
        data = discountInputValue[region].find(el => el.label === label);
        break;
    
      default:
        break;
    }
    
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=shoes`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          status,
          data: {label: data.label, value: data.value}
        }
      };

      axios(config).then((res) => {
        setReloadWebhooks(!reloadWebhooks);

        // setInfoCards(prevState => [...prevState, {
        //   id: new Date().valueOf(),
        //   status: res.data.status,
        //   error: res.data.error,
        //   info: res.data.info,
        //   ms: 6000
        // }]);
      }).catch(() => { });
    } catch (error) { }
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const status = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/nikewebhooks?region=${region}&category=shoes`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          status,
          data: {label: label, value: webhookURL},
        },
      };

      axios(config).then((res) => {
        setLabelInputValue("");
        setWebhookURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);


        // setInfoCards(prevState => [...prevState, {
        //   id: new Date().valueOf(),
        //   status: res.data.status,
        //   error: res.data.error,
        //   info: res.data.info,
        //   ms: 6000
        // }]);
      }).catch(() => { });
    } catch (error) { console.log(error)}
  };

  useEffect(() => {
    setShowWebhooks([]);
    
    const newActiveInputValue = { ...activeInputValue };
    const newCloseoutInputValue = { ...closeoutInputValue };
    const newHoldInputValue = { ...holdInputValue };
    const newDiscountInputValue = { ...discountInputValue };

    regionList.forEach((el, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/nikewebhooks?region=${el.region}&category=shoes`,
        };

        axios(config)
          .then((res) => {
            newActiveInputValue[el.region] = res.data[0].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });
            newCloseoutInputValue[el.region] =  res.data[1].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });
            newHoldInputValue[el.region] = res.data[2].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });
            newDiscountInputValue[el.region] = res.data[3].webhooks.map(webhook => { return { label: webhook.label, value: webhook.value, readOnly: true } });

            setActiveInputValue(newActiveInputValue);
            setCloseoutInputValue(newCloseoutInputValue);
            setHoldInputValue(newHoldInputValue);
            setDiscountInputValue(newDiscountInputValue);
          }).catch(() => {}).finally(() => setShowWebhooks(prevState => [...prevState, 1]));
      }, index * 100);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  }, [uploadMenu]);


  // const [infoCards, setInfoCards] = useState([
  //   {
  //     id: 1,
  //     status: 200,
  //     error: false,
  //     info: "Webhook updated successfully.",
  //     ms: 6000
  //   },
  //   {
  //     id: 2,
  //     status: 200,
  //     error: true,
  //     info: "No info provided.",
  //     ms: 6000
  //   },
  //   {
  //     id: 3,
  //     status: 200,
  //     error: false,
  //     info: "Webhook updated successfully.",
  //     ms: 6000
  //   },
  // ]);

  // useEffect(() => {
  //   const updatedInfoCards = [];
    
  //   if (!infoCards.length) return;

  //   infoCards.forEach((el, index) => {
  //     if (el.ms <= 0) return;

  //     if (index === 0) {
  //       updatedInfoCards.push({
  //         id: el.id,
  //         status: el.status,
  //         error: el.error,
  //         info: el.info,
  //         ms: el.ms - 6000
  //       })
  //     } else {
  //       updatedInfoCards.push(el);
  //     }
  //   });

  //   setTimeout(() => {
  //     console.log(updatedInfoCards);
  //     if (infoCards.length) setInfoCards(updatedInfoCards);
  //   }, 6000);
  // }, [infoCards]);

  const variants = {
    open: { opacity: 1, x: 0, display: "flex" },
    closed: { opacity: 0, x: "0%", display: "none" },
  }

  useEffect(() => {
    const storedRegionList = JSON.parse(localStorage.getItem(`nikeshoes${versionRegionList}`)) || [];

    if (!storedRegionList.length) {
      localStorage.setItem(`nikeshoes${versionRegionList}`, JSON.stringify(regionList))
    } else {
      setRegionList(storedRegionList);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={NikeShoesCSS["root-nike-shoes"]}>
      <div className={NikeShoesCSS["region-container"]}>
        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeShoesCSS["first-background"]}></div> : <></>}

        {showWebhooks.length === regionList.length ? <>
          {regionList.map((el, index) => {
            let title = 'unknown'
            switch (el.region) {
              case "US":
                title = "United States";
                break;
              case "JP":
                title = "Japan";
                break;
              case "SG":
                title = "Singapore";
                break;
              case "MY":
                title = "Malaysia";
                break;
              case "TH":
                title = "Thailand";
                break;
              case "IN":
                title = "India";
                break;
              case "AU":
                title = "Australia";
                break;
              case "CA":
                title = "Canada";
                break;
              case "CN":
                title = "China";
                break;
              case "FR":
                title = "France";
                break;
              case "GB":
                title = "United Kingdom";
                break;
              case "MX":
                title = "Mexico";
                break;
              case "PH":
                title = "Philippines";
                break;
              case "KR":
                title = "Korea";
                break;
              case "TW":
                title = "Taiwan";
                break;
              case "AT":
                title = "Austria";
                break;
              default:
                break;
            }

            return (
              <div className={NikeShoesCSS["region-box"]} key={index}>
                <h2
                  className={`${NikeShoesCSS["region-title"]} ${el.folded ? NikeShoesCSS["region-title-active"] : ""}`}
                  onClick={() => {
                    let newRegionList = { ...regionList };
                    const updatedRegionList = regionList.map(e => {
                      if (e.region === el.region) {
                        return { region: el.region, folded: !e.folded };
                      } else {
                        return e;
                      }
                    });
                    newRegionList = updatedRegionList;
                    setRegionList(newRegionList);
                    
                    localStorage.setItem(`nikeshoes${versionRegionList}`, JSON.stringify(newRegionList));
                  }}
                >
                  Nike {title}
                </h2>

                <motion.div
                  className={`${NikeShoesCSS["region-webhook"]}`}
                  animate={el.folded ? "open" : "closed"}
                  variants={variants}
                >
                  <div className={NikeShoesCSS["webhook"]}>
                    <h3 className={NikeShoesCSS["webhook-status"]}>ACTIVE Webhooks</h3>
                    {activeInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeShoesCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeShoesCSS["webhook-grid"]}>
                            <label className={NikeShoesCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeShoesCSS["webhook-row"]}>
                              <input
                                className={NikeShoesCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newActiveInputValue = { ...activeInputValue };
                                    const updatedWebhook = activeInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newActiveInputValue[el.region] = updatedWebhook;
                                    setActiveInputValue(newActiveInputValue);
                                  }
                                }}
                                disabled={activeInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeShoesCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newActiveInputValue = { ...activeInputValue };
                                  const updatedWebhook = activeInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newActiveInputValue[e.currentTarget.name] = updatedWebhook;
                                  setActiveInputValue(newActiveInputValue);
                                }}
                              ><img className={NikeShoesCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeShoesCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-ACTIVE-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeShoesCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-ACTIVE-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  <div className={NikeShoesCSS["webhook"]}>
                    <h3 className={NikeShoesCSS["webhook-status"]}>CLOSEOUT Webhooks</h3>
                    {closeoutInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeShoesCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeShoesCSS["webhook-grid"]}>
                            <label className={NikeShoesCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeShoesCSS["webhook-row"]}>
                              <input
                                className={NikeShoesCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newCloseoutInputValue = { ...closeoutInputValue };
                                    const updatedWebhook = closeoutInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newCloseoutInputValue[el.region] = updatedWebhook;
                                    setCloseoutInputValue(newCloseoutInputValue);
                                  }
                                }}
                                disabled={closeoutInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeShoesCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newCloseoutInputValue = { ...closeoutInputValue };
                                  const updatedWebhook = closeoutInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newCloseoutInputValue[e.currentTarget.name] = updatedWebhook;
                                  setCloseoutInputValue(newCloseoutInputValue);
                                }}
                              ><img className={NikeShoesCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeShoesCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-CLOSEOUT-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeShoesCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-CLOSEOUT-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  <div className={NikeShoesCSS["webhook"]}>
                    <h3 className={NikeShoesCSS["webhook-status"]}>HOLD Webhooks</h3>
                    {holdInputValue[el.region].map((webhook, index) => {
                      return (
                        <form className={NikeShoesCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                          <div className={NikeShoesCSS["webhook-grid"]}>
                            <label className={NikeShoesCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                            <div className={NikeShoesCSS["webhook-row"]}>
                              <input
                                className={NikeShoesCSS["webhook-input"]}
                                value={webhook.value}
                                name={el.region}
                                type="url"
                                id={webhook.label}
                                required 
                                onChange={e => {
                                  if (e.target.name === el.region) {
                                    let newHoldInputValue = { ...holdInputValue };
                                    const updatedWebhook = holdInputValue[el.region].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                      } else {
                                        return el;
                                      }
                                    });
                                    newHoldInputValue[el.region] = updatedWebhook;
                                    setHoldInputValue(newHoldInputValue);
                                  }
                                }}
                                disabled={holdInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                              />
                              <button className={NikeShoesCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                onClick={e => {
                                  let newHoldInputValue = { ...holdInputValue };
                                  const updatedWebhook = holdInputValue[e.currentTarget.name].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                    } else {
                                      return el;
                                    }
                                  });
                                  newHoldInputValue[e.currentTarget.name] = updatedWebhook;
                                  setHoldInputValue(newHoldInputValue);
                                }}
                              ><img className={NikeShoesCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                              <button className={NikeShoesCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-HOLD-UPDATE"} type="submit"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                              <button className={NikeShoesCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-HOLD-DELETE"} type="button"
                                onClick={handleSubmit}
                              ><img className={NikeShoesCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                            </div>
                          </div>
                        </form>
                      );
                    })}
                  </div>

                  {el.region === 'US' ? 
                    <div className={NikeShoesCSS["webhook"]}>
                      <h3 className={NikeShoesCSS["webhook-status"]}>Discount Webhooks</h3>
                      {discountInputValue[el.region].map((webhook, index) => {
                        return (
                          <form className={NikeShoesCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                            <div className={NikeShoesCSS["webhook-grid"]}>
                              <label className={NikeShoesCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                              <div className={NikeShoesCSS["webhook-row"]}>
                                <input
                                  className={NikeShoesCSS["webhook-input"]}
                                  value={webhook.value}
                                  name={el.region}
                                  type="url"
                                  id={webhook.label}
                                  required 
                                  onChange={e => {
                                    if (e.target.name === el.region) {
                                      let newDiscountInputValue = { ...discountInputValue };
                                      const updatedWebhook = discountInputValue[el.region].map(el => {
                                        if (el.label === webhook.label) {
                                          return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                        } else {
                                          return el;
                                        }
                                      });
                                      newDiscountInputValue[el.region] = updatedWebhook;
                                      setDiscountInputValue(newDiscountInputValue);
                                    }
                                  }}
                                  disabled={discountInputValue[el.region].find(el => el.label === webhook.label).readOnly}
                                />
                                <button className={NikeShoesCSS["webhook-edit-btn"]} id={webhook.label} name={el.region} type="button"
                                  onClick={e => {
                                    let newDiscountInputValue = { ...discountInputValue };
                                    const updatedWebhook = discountInputValue[e.currentTarget.name].map(el => {
                                      if (el.label === webhook.label) {
                                        return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                      } else {
                                        return el;
                                      }
                                    });
                                    newDiscountInputValue[e.currentTarget.name] = updatedWebhook;
                                    setDiscountInputValue(newDiscountInputValue);
                                  }}
                                ><img className={NikeShoesCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                                <button className={NikeShoesCSS["webhook-save-btn"]} id={webhook.label} name={el.region + "-DISCOUNT-UPDATE"} type="submit"
                                  onClick={handleSubmit}
                                ><img className={NikeShoesCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                                <button className={NikeShoesCSS["webhook-delete-btn"]} id={webhook.label} name={el.region + "-DISCOUNT-DELETE"} type="button"
                                  onClick={handleSubmit}
                                ><img className={NikeShoesCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                              </div>
                            </div>
                          </form>
                        );
                      })}
                    </div> : null}
                </motion.div>
              </div>
            )
          })} </> : <div className={NikeShoesCSS["access-spin"]}><span className={NikeShoesCSS["access-spinner"]}></span></div>}

        <div className={NikeShoesCSS["bottom-container"]}>
          <div className={NikeShoesCSS["menu-box"]}>
            <div onClick={() => setUploadMenu(!uploadMenu)} className={NikeShoesCSS["menu-btn"]}>
              <img className={NikeShoesCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${NikeShoesCSS["upload-container"]} ${uploadMenu ? NikeShoesCSS["upload-container-show"] : ""}`}>
            <h2 className={NikeShoesCSS["upload-form-title"]} htmlFor="region">Enter the new webhook</h2>
            
            <form className={NikeShoesCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewWebhookSubmit}>
              <input className={NikeShoesCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
              <input className={NikeShoesCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="url" name="webhook" placeholder="Webhook URL" required />
              
              <select className={NikeShoesCSS["upload-select-region"]} name="region">
                <option value="US">United States</option>
                <option value="JP">Japan</option>
                <option value="SG">Singapore</option>
                <option value="MY">Malaysia</option>
                <option value="TH">Thailand</option>
                <option value="IN">India</option>
                <option value="AU">Australia</option>
                <option value="CA">Canada</option>
                <option value="CN">China</option>
                <option value="FR">France</option>
                <option value="GB">United Kingdom</option>
                <option value="MX">Mexico</option>
                <option value="PH">Philippines</option>
                <option value="KR">Korea</option>
                <option value="TW">Taiwan</option>
                <option value="AT">Austria</option>
              </select>

              <select className={NikeShoesCSS["upload-select-status"]} name="status">
                <option value="ACTIVE">Active</option>
                <option value="CLOSEOUT">Closeout</option>
                <option value="HOLD">Hold</option>
                <option value="DISCOUNT">Discount</option>
              </select>

              <button className={NikeShoesCSS["upload-submit-btn"]} type="submit">Upload</button>
              <button className={NikeShoesCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
            </form>
          </div>
        </div>

        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={NikeShoesCSS["second-background"]}></div> : <></>}

        {/* <div className={NikeShoesCSS["sidebar"]}>
          {infoCards.map((el, index) => {
            return (
              <div style={{backgroundColor: `${el.error ? "#DF2E43" : "#7ee24f" }`}} className={NikeShoesCSS["info-tab"]} key={index}>
                <img style={{filter: `${el.error ? "invert(100%) sepia(100%) saturate(1%) hue-rotate(8deg) brightness(102%) contrast(101%)" : "" }`}} className={NikeShoesCSS["info-img"]} src={el.error ? errorImg : successImg} alt={`${el.error ? "error" : "success"}-img`} />
                <p style={{color: `${el.error ? "var(--foggy-grey)" : "var(--dark-grey)" }`}} className={NikeShoesCSS["info-desc"]}>{el.info}</p>
              </div>
            );
          })}
        </div> */}

        {/* <div className={NikeShoesCSS["info-tab"]}>
          <img className={NikeShoesCSS["info-img"]} src={successImg} alt="success-img" />
          <p className={NikeShoesCSS["info-desc"]}>Webhook updated successfully.</p>
        </div> */}
      </div>
    </div>
  );
}

export default NikeShoes;
