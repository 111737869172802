import { useState } from "react";
import axios from "axios";
import ProductQueryCSS from "../css/productquery.module.css";
import searchImg from "../assets/search.svg";
import downloadImg from "../assets/download.svg";

function ProductQuery() {
  const [inputSKUSearchValue, setInputSKUSearchValue] = useState("");
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [productsData, setProductsData] = useState(false);

  const handleSKUQuery = (e) => {
    e.preventDefault();
    setIsSearchClicked(true);
    setProductsData(false);

    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/api/productquery',
      headers: { 
        'Content-Type': 'application/json'
      },
      params: {
        sku: inputSKUSearchValue
      }
    };
    
    axios(config)
      .then((res) => {
        if (res.data.data === "null") return setIsSearchClicked(false);

        setProductsData(res.data.data);
        setIsSearchClicked(false);
      }).catch(() => { setIsSearchClicked(false); });
  };

  const handleDownload = async e => {
    const imageUrl = e.currentTarget.id;
    const [sku, index] = e.currentTarget.name.split("-");
    const fileExtension = "png";

    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        throw new Error('Image not found');
      }

      const imageBlob = await response.blob();

      const customFileName = window.prompt('Enter file name:', `image_${sku}_${index}`);
      if (customFileName === null) return;

      const downloadLink = document.createElement('a');
      const imageUrlObjectURL = URL.createObjectURL(imageBlob);

      downloadLink.setAttribute('download', `${customFileName}.${fileExtension}`);
      downloadLink.href = imageUrlObjectURL;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      URL.revokeObjectURL(imageUrlObjectURL);
      document.body.removeChild(downloadLink);
    } catch (err) { }
  };

  return (
    <div className={ProductQueryCSS["product-query"]}>
      <h2 className={ProductQueryCSS["sku-search-title"]}>Search Product</h2>

      <form onSubmit={handleSKUQuery} action="" method="post" className={ProductQueryCSS["search-container"]}>
        <input className={ProductQueryCSS["sku-search-input"]} type="text" name="search-sku" id="sku-search" placeholder="SKU" value={inputSKUSearchValue} onChange={e => setInputSKUSearchValue(e.target.value)}/>
        <img onClick={handleSKUQuery} className={ProductQueryCSS["search-img"]} src={searchImg} alt="search-img" name="search"/>
      </form>

      <div className={isSearchClicked ? ProductQueryCSS["search-spin"] : ""}>
        <span className={ProductQueryCSS["search-spinner"]}></span>
      </div>

      <div className={ProductQueryCSS["product-section"]}>
        {productsData ?
          Object.keys(productsData).map((region) => {
            return (
            productsData[region].id ?
              <div className={ProductQueryCSS["product-container"]}>
                <div className={ProductQueryCSS["product-data"]}>
                  <div className={ProductQueryCSS["product-region"]}><p>Region: <span>{region}</span></p></div>
                  <div className={ProductQueryCSS["product-title"]}><p>Title: <span>{productsData[region].title}</span></p></div>
                  <div className={ProductQueryCSS["product-sku"]}><p>SKU: <span>{productsData[region].styleColor}</span></p></div>
                  <div className={ProductQueryCSS["product-subtitle"]}><p>Subtitle: <span>{productsData[region].subtitle}</span></p></div>
                  <div className={ProductQueryCSS["product-commercestartdate"]}><p>Commerce Start Date: <span>{new Date(productsData[region].commerceStartDate).toLocaleDateString()}</span></p></div>
                  <div className={ProductQueryCSS["product-price"]}><p>Price: <span>{productsData[region].price} {productsData[region].currency}</span></p></div>
                  <div className={ProductQueryCSS["product-discounted"]}><p>Discounted: <span>{productsData[region].discounted ? "Yes" : "No"}</span></p></div>
                  <div className={ProductQueryCSS["product-promo"]}><p>Promo Exclusion: <span>{productsData[region].isPromoExclusionMessage ? "Yes" : "No"}</span></p></div>
                  <div className={ProductQueryCSS["product-colordescription"]}><p>Color Description: <span>{productsData[region].colorDescription}</span></p></div>
                  <div className={ProductQueryCSS["product-slug"]}><p>Slug: <span>{productsData[region].slug}</span></p></div>
                  <div className={ProductQueryCSS["product-descriptionheading"]}><p>Description Heading: <span>{productsData[region].descriptionHeading}</span></p></div>          
                  <div className={ProductQueryCSS["product-description"]}><p>Description: <span>{productsData[region].description}</span></p></div>
                </div>

                <div className={ProductQueryCSS["product-images"]}>
                  {productsData[region].images.map(((img, i) => (
                    <div className={ProductQueryCSS["product-image-box"]}>
                      <img className={ProductQueryCSS["product-preview-image"]} src={img} alt="preview-img" />
                      <img onClick={handleDownload} className={ProductQueryCSS["download-img"]} src={downloadImg} name={productsData[region].styleColor.replace("-", "_") + "-" + (i + 1)} alt="download-img" id={img} />
                    </div>
                  )))}
                </div>
              </div> : <></>
          )})
        :
          <></>
        }
      </div>
    </div>
  );
}

export default ProductQuery;
