import PrivacyPolicyandTosCSS from "./css/privacypolicyandtos.module.css";

function PrivacyPolicyAndToS() {
  return (
    <div className={PrivacyPolicyandTosCSS["privacy-policy-and-tos"]}>
      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Terms of Service</h1>
        <h2>Terms of service and use regarding Elden Monitors</h2>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>Thank you for considering Elden Ventures for your tool needs. Please review and accept the following terms before using our services:</h3>
        <li>Elden Ventures is not responsible for any damages that may occur as a result of using our tools, including but not limited to, any data breaches that may occur.</li>
        <li>Our tool requires access to your private information, specifically your Bearer Token for your Nike account. This may give us access to other personal data related to your Nike account, including saved payment methods and addresses.</li>
        <li>By using our services, you acknowledge that you are willingly providing us with this information, and that you must take responsibility for protecting yourself and your data. This includes changing and updating your information frequently (at least every hour of usage) to minimize the risk of a data breach.</li>
        <li>To maximize security, we recommend that you use a burner account, merchant locked cards, and avoid saving your address to the account. Do not use your main accounts.</li>
        <li>By using our service, you agree that Elden Ventures is not responsible for any damages that may occur as a result of using our tools, and that you take full responsibility for protecting yourself and your data.</li>
        <li>Please note that we take security seriously and have many multi-step security measures in place to ensure the safety of our servers and your data. However, in the unlikely event of a breach, we are not responsible for any damages.</li>
        <li>Under no circumstances will we ever place orders on your behalf unless you explicitly request. Our tool is designed to assist you in managing your Nike account, not to make purchases without your consent.</li>
      
        <h3>By accepting these terms, you agree to use our services at your own risk and assume full responsibility for any damages that may occur as a result of using our tools. Thank you for your understanding, and please contact us if you have any questions or concerns.</h3>

        <h3>As Elden Ventures, we take seriously our commitment to providing a safe and secure platform for our users. To ensure transparency, please carefully review the following terms and conditions:</h3>        
        <li>Limitation of Liability: At Elden Ventures, we prioritize the protection of our users. Therefore, we will not be held liable for any indirect, special, incidental, consequential, exemplary, or punitive damages that arise from your use or misuse of our site, including but not limited to loss of revenue, anticipated profits, lost business, data or sales, or cost of substitute services. Please note that some jurisdictions may not allow the limitation or exclusion of liability, and as such, some of the above limitations may not apply to you.</li>
        <li>Indemnification: By using our site, you agree to defend, indemnify and hold Elden Ventures, our directors, officers, employees, agents, and affiliates harmless from any claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, arising from your use of the site, your violation of the terms, or the posting or transmission of any materials on or through the site by you, including but not limited to any third-party claim that any information or materials you provide infringes any third-party proprietary right.</li>
        <li>Privacy: We take the privacy and security of our users' account information seriously at Elden Ventures. We never share this information with any outside entities or individuals, and all data is secured and encrypted with the Secure Socket Layer Software. Please review our Privacy Policy for further details. You acknowledge that you are solely responsible for the accuracy and content of any information you provide on our site.</li>
        <li>Termination Clause: We reserve the right to terminate your access to our site, without cause or notice. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including but not limited to ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</li>
        <li>Disclaimer of Warranties: By using our site, you acknowledge and agree that the site, including all content, function, materials, and services, is provided "as is," without warranty of any kind, either express or implied, including but not limited to any warranty for information, data, data processing services or uninterrupted access, any warranties concerning the availability, accuracy, completeness, usefulness, or content of information, and any warranties of title, non-infringement, merchantability or fitness for a particular purpose. We do not warrant that the site or the function, content, or services made available thereby will be timely, secure, uninterrupted, or error-free, or that defects will be corrected. We make no warranty that the site will meet users' expectations or requirements. No advice, results, or information, or materials, whether oral or written, obtained by you through the site shall create any warranty not expressly made herein. If you are dissatisfied with the site, your sole remedy is to discontinue using the site.</li>

        <h3>You are willingly inputting your private information into our site, by doing so you relieve Elden Ventures from all responsibilities in the highly unlikely event of a data breach, or any other unfortunate circumstances that may occur due to the nature of this feild.</h3>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Payment Collection</h1>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <li>We will use whop.com to collect and manage all personal payment information.</li>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Cancellation/Refund Policy</h1>
        <h2>All Sales Final Policy for Digital Products - Elden Monitors</h2>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>Effective Date: August, 2023</h3>
        <li>Thank you for choosing Elden Monitors for your digital product needs. Before making a purchase, please carefully read and understand our "All Sales Final" policy outlined below.</li>

        <h3>Definition of Digital Products:</h3>
        <li>Digital products include, but are not limited to, software, e-books, digital downloads, online courses, and any other downloadable or streamable content provided by Elden Monitors.</li>

        <h3>All Sales Final:</h3>
        <li>Once a digital product is purchased, all sales are final. We do not offer refunds, returns, or exchanges for digital products. It is the responsibility of the customer to ensure that they have selected the correct product and have reviewed the product description, system requirements, and any other relevant information before making a purchase.</li>

        <h3>No Refunds:</h3>
        <li>Due to the nature of digital products, we do not provide refunds for any reason, including but not limited to, dissatisfaction with the product, change of mind, or inability to use the product. Please make sure to thoroughly evaluate the product through any available demos, trials, or product descriptions before completing your purchase.</li>

        <h3>Product Support:</h3>
        <li>While all sales are final, Elden Monitors is committed to providing support for technical issues related to the functionality of the digital product. If you encounter any issues, please contact our customer support team our discord for assistance.</li>

        <h3>Licensing Agreement:</h3>
        <li>Purchasing a digital product from Elden Monitors is subject to the terms and conditions outlined in the product's licensing agreement. Please review the licensing agreement carefully before using the product.</li>
      
        <h3>Unauthorized Distribution:</h3>
        <li>Customers are prohibited from sharing, distributing, or reselling digital products purchased from Elden Monitors without explicit written permission. Violation of this policy may result in legal action.</li>
      
        <h3>Changes to Policy:</h3>
        <li>Elden Monitors reserves the right to modify or update this "All Sales Final" policy at any time. Customers will be notified of any changes, and the updated policy will be effective immediately upon posting.</li>
        <li>By completing a purchase with Elden Monitors, you acknowledge that you have read, understood, and agreed to the terms of this "All Sales Final" policy.</li>
        <li>If you have any questions or concerns regarding this policy, please contact our customer support team on our discord.</li>
        <li>Thank you for choosing Elden Monitors. We appreciate your business.</li>

        <h3>Use of Website and Intellectual Property Rights - Elden Monitors</h3>
        <li>By accessing <a href="https://eldenmonitors.com/" target="_blank" rel="noopener noreferrer">https://eldenmonitors.com/</a>, you are granted a non-exclusive, non-transferable, and revocable license, subject to these terms of use. Your use of the site is conditioned on the agreement that you will not engage in any unlawful or prohibited activities as outlined in these Terms. It is prohibited to use the site in a manner that could harm, disable, overburden, or impair it, or interfere with others' enjoyment of the site. Any attempt to obtain materials or information through unauthorized means is strictly forbidden.</li>      
        <li>All content provided as part of the service, including text, graphics, logos, images, and software, is the property of Elden Monitors or its suppliers, protected by copyright and other intellectual property laws. Users must respect and comply with all copyright and proprietary notices, legends, or restrictions associated with such content and must not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or exploit any content found on the site without prior written permission.</li>
        <li>Elden Monitors' content is not for resale, and users are not entitled to make any unauthorized use of protected content. Users may use protected content solely for personal purposes and must obtain express written permission from Elden Monitors and the copyright owner for any other use. Ownership rights in protected content are not acquired through the use of the site. No licenses, whether express or implied, to the intellectual property of Elden Monitors or its licensors are granted except as expressly authorized by these Terms.</li>

        <h3>Indemnification</h3>
        <li>You agree to indemnify, defend, and hold harmless Elden Monitors, its officers, directors, employees, agents, and third parties from any losses, costs, liabilities, and expenses (including reasonable attorney's fees) related to or arising from your use of or inability to use the site or services, any user postings made by you, your violation of any terms of this Agreement, or your violation of any rights of a third party, or your violation of any applicable laws, rules, or regulations. Elden Monitors acknowledges that the intellectual property rights are owned and held by a private third-party company, and any disputes related to intellectual property should be addressed directly with the owner. Elden Monitors reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Elden Monitors in asserting any available defenses.</li>

        <h3>Arbitration</h3>
        <li>If the parties cannot resolve any dispute arising from or concerning these Terms and Conditions, such dispute shall be resolved through final and binding arbitration under the Federal Arbitration Act. The arbitration shall be conducted by a single neutral arbitrator and administered by the American Arbitration Association or a similar arbitration service chosen by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court with jurisdiction. The prevailing party in any legal action, proceeding, or arbitration arising from or concerning these Terms and Conditions shall be entitled to recover its costs and reasonable attorney's fees.</li>
      
        <h3>Class Action Waiver</h3>
        <li>Arbitration under these Terms and Conditions will be conducted on an individual basis; class arbitrations and class/representative/collective actions are not permitted. The parties agree that any claims against each other will be brought only in their individual capacity and not as part of a class, collective, or representative proceeding. The arbitrator may not consolidate more than one person's claims or preside over any form of a representative or class proceeding unless both you and Elden Monitors agree otherwise.</li>
      
        <h3>Liability Disclaimer</h3>
        <li>Elden Monitors and its suppliers make no representations about the suitability, reliability, availability, timeliness, and accuracy of the information, software, products, services, and related graphics contained on the site for any purpose. All such information, software, products, services, and related graphics are provided "as is" without warranty or condition of any kind. To the maximum extent permitted by applicable law, Elden Monitors and its suppliers disclaim all warranties and conditions regarding this information, software, products, services, and related graphics, including all implied warranties or conditions of merchantability, fitness for a particular purpose, title, and non-infringement. In no event shall Elden Monitors or its suppliers be liable for any direct, indirect, punitive, incidental, special, or consequential damages arising out of or in any way connected with the use or performance of the site, whether based on contract, tort, negligence, strict liability, or otherwise, even if Elden Monitors or any of its suppliers has been advised of the possibility of damages.</li>

        <h3>Termination/Access Restriction</h3>
        <li>Elden Monitors reserves the right to terminate your access to the site and related services, or any portion thereof, at any time, without notice. If a user has been removed from the service or notified of termination, Elden Monitors holds no responsibility should the user attempt to access the website using alternative means. Once usage has been terminated, the user waives all rights under the law and is considered an unauthorized user. No joint venture, partnership, employment, or agency relationship exists between you and Elden Monitors as a result of this agreement. Elden Monitors' performance is subject to existing laws and legal processes, and nothing in this agreement is in derogation of Elden Monitors' right to comply with governmental, court, and law enforcement requests or requirements related to your use of the site. If any part of this agreement is determined to be invalid or unenforceable, the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision. The remainder of the agreement shall continue in effect. Unless otherwise specified, this agreement constitutes the entire agreement between the user and Elden Monitors and supersedes all prior or contemporaneous communications and proposals. A printed version of this agreement and any notice given in electronic form shall be admissible in judicial or administrative proceedings based on or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish of the parties that this agreement and all related documents be written in English.</li>

        <h3>Client Usage Terms</h3>
        <li>Elden Monitors must not be accessible to the public. Open invitations or open source provisions granting public access to monitors or digital services are strictly prohibited. Any damages suffered by Elden Monitors and its affiliates due to such provisions will be the responsibility of the user.</li>
        <li>Elden Monitors and all other products on this site may not be resold or redistributed as derivative works.</li>
        <li>Elden Monitors reserves the right to remove its advertising from your group's social media or website. Marketing using the Elden Monitors brand is prohibited unless permission is requested and granted by Elden Monitors and/or its affiliates.</li>
        <li>Elden Monitors reserves the right to request usage documentation and view this usage in the group or server where it is provided. Failure to grant this viewership or usage documentation may result in termination of your subscription.</li>

        <h3>Changes to Terms</h3>
        <li>Elden Monitors reserves the right to change the Terms under which <a href="https://eldenmonitors.com/" target="_blank" rel="noopener noreferrer">https://eldenmonitors.com/</a> is offered at its sole discretion. The most current version of the Terms will supersede all previous versions. Elden Monitors encourages you to periodically review the Terms to stay informed of updates.</li>

        <h3>Oral Modifications</h3>
        <li>No amendments or modifications to this agreement shall be binding unless put forth in writing.</li>

        <h3>Entire Agreement</h3>
        <li>This term of use document constitutes all existing policy under the Elden Monitors brand for the usage of the website.</li>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Operational Agreement for Elden Monitors</h1>
        <h2>Intellectual Property (IP), Software, and Services:</h2>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>Third-Party Licensing:</h3>
        <li>Elden Monitors operates under the licensing agreements for all intellectual property, software, and services utilized. These agreements are established with a third party, and Elden Monitors is granted a license to use and distribute these assets.</li>

        <h3>Responsibility of Elden Monitors:</h3>
        <li>Elden Monitors, herein referred to as the payment collection company, serves as a facilitator for the collection and distribution of payments related to the licensed intellectual property, software, and services. Elden Monitors is not the owner or creator of the IP, software, or services.</li>
      
        <h3>Limitation of Responsibility:</h3>
        <li>Under no circumstances is Elden Monitors responsible for any damages that may occur in connection with the use of the licensed IP, software, or services. This includes, but is not limited to, damages arising from the functionality, performance, or any issues related to the licensed assets.</li>

        <h3>No Ownership Claim:</h3>
        <li>Elden Monitors does not claim ownership of the intellectual property, software, or services licensed from the third party. Any rights not expressly granted in the licensing agreements remain with the original owner or licensor.</li>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Indemnification:</h1>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>User Responsibility:</h3>
        <li>Users of Elden Monitors' services acknowledge that any indemnification claims, legal disputes, or liabilities related to the intellectual property, software, or services must be directed to the third party that owns and licenses the assets.</li>

        <h3>Elden Monitors' Limited Role:</h3>
        <li>Elden Monitors agrees to cooperate with users in addressing any issues related to payment collection or service facilitation. However, Elden Monitors has a limited role in the overall functionality, development, or maintenance of the licensed assets.</li>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Disclaimer of Damages:</h1>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>No Warranty by Elden Monitors:</h3>
        <li>Elden Monitors disclaims any warranties, express or implied, regarding the intellectual property, software, or services. This includes but is not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>

        <h3>Use at Own Risk:</h3>
        <li>Users acknowledge that their use of the licensed assets is at their own risk. Elden Monitors does not guarantee the accuracy, completeness, or reliability of the licensed assets and shall not be held liable for any consequences resulting from their use.</li>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Termination:</h1>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>Termination of Services:</h3>
        <li>Elden Monitors reserves the right to terminate its services related to the collection and distribution of payments if required by the third-party licensing agreements or in the event of any breach of terms by users.</li>
        
        <h3>No Liability for Termination:</h3>
        <li>Elden Monitors shall not be liable for any losses or damages incurred by users or third parties due to the termination of services. Users are responsible for ensuring compliance with all relevant terms and conditions.</li>

        <h3>This operational agreement is effective upon acceptance by users and remains in force until terminated as per the outlined provisions. By using Elden Monitors' services, users acknowledge their understanding and acceptance of the terms specified in this operational agreement.</h3>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-first-section"]}>
        <h1>Our links can contain affilliate links and it is showen in the product name link through the /affilliate part of the domain:</h1>
      </div>

      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <li>Webhooks that contain #ad on their footer contain 100% contain an affiliate link, others may or may not.</li>
        <li>Direct web links and app links do not have affilliate reroute</li>
      </div>
      
      <div className={PrivacyPolicyandTosCSS["tos-second-section"]}>
        <h3>Have any questions? Reach out to us:</h3>
        <h3>Email: <a href="mailto:eldensoftware@gmail.com">eldensoftware@gmail.com</a></h3>
        <h3>Twitter: <a href="https://twitter.com/EldenMonitors" target="_blank" rel="noopener noreferrer">https://twitter.com/EldenMonitors</a></h3>
        <h3>Discord: <a href="https://discord.gg/g3W3WHpwus" target="_blank" rel="noopener noreferrer">https://discord.gg/g3W3WHpwus</a></h3>
      </div>
    </div>
  )
}

export default PrivacyPolicyAndToS;