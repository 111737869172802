import { useEffect } from "react";
import Navigation from "./components/Navigation";
import TopSection from "./components/TopSection";
import MiddleSection from "./components/MiddleSection";
import BottomSection from "./components/BottomSectiom";
import Footer from "./components/Footer";
import AOS from "aos";
import "./css/Default Styles/general.css";

function Home() {
  useEffect(() => {
    AOS.init({
      offset: 300,
      duration: 100,
      easing: "ease-in-out-quart",
    });
  }, []);

  return (
    <div id="root-home">
      <Navigation />
      <TopSection />
      <MiddleSection />
      <BottomSection />
      <Footer />
    </div>
  );
}

export default Home;