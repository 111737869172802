import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/redux";
import axios from "axios";
import Sidebar from "./components/SideBar";
import Content from "./components/Content";
import GeneralCSS from "./css/general.module.css";
import Loading from "./components/Loading";

function Dashboard() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    axios.get("/api/user")
      .then(res => {
        if (res.data === "unauthorized") {
          window.location.replace("/auth");
        } else {
          setLoggedIn(true);
        }
      }).catch(() => {
        console.log("DASHBOARD run");
        // window.location.replace("/")
      });
  }, []);

  return (
    <div id={GeneralCSS["root-dashboard"]}>
    {loggedIn ? (
      <Provider store={store}>
        <Sidebar path=":testId" />
        
        <Routes>
          <Route path=":guildId/*" element={<Content />} />
        </Routes>
      </Provider>
    ) : (
      <Loading />
    )}
    </div>
  );
}

export default Dashboard;