import { useEffect, useState } from "react";
import axios from "axios";
import SubmitFormCSS from "../css/submitform.module.css";
import "aos/dist/aos.css";

function SubmitForm() {
  const [inputValue, setInputValue] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [showInfo, setShowInfo] = useState("");
  const [isATCToSMenuActive, setIsATCToSMenuActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowInfo("");
    }, 8000);
  }, [showInfo]);

  const handleSubmit = event => {
    event.preventDefault();
    setIsClicked(true);

    setTimeout(() => {
      axios.post('/nike/accesstoken', {
        accesstoken: inputValue
      }).then(() => {}).catch(() => {});

      setShowInfo("sent");
      setIsClicked(false);
    }, 1000);
  };

  const handleDownload = () => {
    // URL to your ZIP file
    const url = 'https://eldenmonitors.com/elden-extension.zip';
    
    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.download = 'elden-extension.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEscapeKeyPress = (e) => {
    if (e.key === 'Escape'){
      setIsATCToSMenuActive(false);
    };
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKeyPress);

    return () => { window.removeEventListener('keydown', handleEscapeKeyPress); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={SubmitFormCSS["submit-form-container"]}>
      <div className={SubmitFormCSS["tos-container"]}>
        <div className={SubmitFormCSS["tos-first-section"]}>
          <h1>Terms of Service</h1>
          <h2>Terms of service and use regarding Elden Monitors</h2>
        </div>

        <div className={SubmitFormCSS["tos-second-section"]}>
          <h3>Thank you for considering Elden Ventures for your tool needs. Please review and accept the following terms before using our services:</h3>

          <li>Elden Ventures is not responsible for any damages that may occur as a result of using our tools, including but not limited to, any data breaches that may occur.</li>
          <li>Our tool requires access to your private information, specifically your Bearer Token for your Nike account. This may give us access to other personal data related to your Nike account, including saved payment methods and addresses.</li>
          <li>By using our services, you acknowledge that you are willingly providing us with this information, and that you must take responsibility for protecting yourself and your data. This includes changing and updating your information frequently (at least every hour of usage) to minimize the risk of a data breach.</li>
          <li>To maximize security, we recommend that you use a burner account, merchant locked cards, and avoid saving your address to the account. Do not use your main accounts.</li>
          <li>By using our service, you agree that Elden Ventures is not responsible for any damages that may occur as a result of using our tools, and that you take full responsibility for protecting yourself and your data.</li>
          <li>Please note that we take security seriously and have many multi-step security measures in place to ensure the safety of our servers and your data. However, in the unlikely event of a breach, we are not responsible for any damages.</li>
          <li>Under no circumstances will we ever place orders on your behalf unless you explicitly request. Our tool is designed to assist you in managing your Nike account, not to make purchases without your consent.</li>
        
          <h3>By accepting these terms, you agree to use our services at your own risk and assume full responsibility for any damages that may occur as a result of using our tools. Thank you for your understanding, and please contact us if you have any questions or concerns.</h3>
          
          <h3>As Elden Ventures, we take seriously our commitment to providing a safe and secure platform for our users. To ensure transparency, please carefully review the following terms and conditions:</h3>
          
          <li>Limitation of Liability: At Elden Ventures, we prioritize the protection of our users. Therefore, we will not be held liable for any indirect, special, incidental, consequential, exemplary, or punitive damages that arise from your use or misuse of our site, including but not limited to loss of revenue, anticipated profits, lost business, data or sales, or cost of substitute services. Please note that some jurisdictions may not allow the limitation or exclusion of liability, and as such, some of the above limitations may not apply to you.</li>
          <li>Indemnification: By using our site, you agree to defend, indemnify and hold Elden Ventures, our directors, officers, employees, agents, and affiliates harmless from any claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, arising from your use of the site, your violation of the terms, or the posting or transmission of any materials on or through the site by you, including but not limited to any third-party claim that any information or materials you provide infringes any third-party proprietary right.</li>
          <li>Privacy: We take the privacy and security of our users' account information seriously at Elden Ventures. We never share this information with any outside entities or individuals, and all data is secured and encrypted with the Secure Socket Layer Software. Please review our Privacy Policy for further details. You acknowledge that you are solely responsible for the accuracy and content of any information you provide on our site.</li>
          <li>Termination Clause: We reserve the right to terminate your access to our site, without cause or notice. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including but not limited to ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</li>
          <li>Disclaimer of Warranties: By using our site, you acknowledge and agree that the site, including all content, function, materials, and services, is provided "as is," without warranty of any kind, either express or implied, including but not limited to any warranty for information, data, data processing services or uninterrupted access, any warranties concerning the availability, accuracy, completeness, usefulness, or content of information, and any warranties of title, non-infringement, merchantability or fitness for a particular purpose. We do not warrant that the site or the function, content, or services made available thereby will be timely, secure, uninterrupted, or error-free, or that defects will be corrected. We make no warranty that the site will meet users' expectations or requirements. No advice, results, or information, or materials, whether oral or written, obtained by you through the site shall create any warranty not expressly made herein. If you are dissatisfied with the site, your sole remedy is to discontinue using the site.</li>

          <h3>You are willingly inputting your private information into our site, by doing so you relieve Elden Ventures from all responsibilities in the highly unlikely event of a data breach, or any other unfortunate circumstances that may occur due to the nature of this feild.</h3>
        </div>
      </div>

      <form className={SubmitFormCSS["form-container"]} action="" method="post" onSubmit={handleSubmit}>
        <label htmlFor="inputName">Enter the Access Token</label>
        <textarea
          className={SubmitFormCSS["token-input"]}
          type="text"
          id="inputToken"
          name="inputToken"
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          placeholder="Access Token"
          required
        />
        <button className={isClicked ? SubmitFormCSS["spin"] : ""} type="submit">
          {showInfo === "sent" && <div className={SubmitFormCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Token sent successfully</p></div>}
          {showInfo === "error" && <div className={SubmitFormCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
          Submit Token
          <span className={SubmitFormCSS["spinner"]}></span>
        </button>
        <a href="https://elden-software-solutions.gitbook.io/elden-software-solutions/how-to-get-access_token" target="_blank" rel="noopener noreferrer">Guide for Access Token</a>
        
        <h4>Read terms of services before downloading</h4>

        <button type="button" className={SubmitFormCSS["atc-download-btn"]} onClick={() => setIsATCToSMenuActive(!isATCToSMenuActive)} href="#atclink">Download ATC Extension</button>
        
        <li>1 - Download extension</li>
        <li>2 - Use a new Chrome user, we suggest that you use one specific to Nike and on New chrome accounts for cleanest flow</li>
        <li>3 - After you are logged in to your New chrome account, Go here: <code>chrome://extensions/</code> Turn on developer mode on the top right corner</li>
        <li>4 - Click Load Unpacked </li>
        <li>5 - Select the downloaded Elden ATC Extension, and load it.</li>
        <li>6 - Once loaded make sure that it is toggled  ON when using and off when you are not.</li>
        <p>You can now ATC any FLOW Nike product of ACTIVE or CLOSEOUT status. You do not have to submit your access_token to use this, once downloaded it is good to use on your computer at any time.</p>

        {isATCToSMenuActive ?
          <div className={SubmitFormCSS["atc-extension-container"]}>
            <div className={SubmitFormCSS["atc-tos-section"]}>
              <div className={SubmitFormCSS["atc-tos-first-section"]}>
                <h1>ATC Terms Of Service and Agreements</h1>
                <h2>Terms of Service and Agreement</h2>
              </div>

              <div className={SubmitFormCSS["atc-tos-second-section"]}>
                <li>Welcome to the Nike Add To Cart (ATC) Chrome extension ("Extension") provided by Elden Software Solutions (A20 Holdings)  ("Provider"). Please read these terms and conditions carefully before installing or using the Extension. By accessing or using the Extension, you agree to be bound by these terms and conditions. If you disagree with any part of these terms, you may not use the Extension. You relieve us of this</li>

                <h3>1. Limitation of Liability:</h3>
                <li>Provider shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from the use or inability to use the Extension. Provider expressly disclaims all warranties of any kind, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
              
                <h3>2. Nike Bans:</h3>
                <li>Users acknowledge and agree that Provider shall not be responsible for any actions taken by Nike, including but not limited to, account suspension or banning, as a result of the use of the Extension. Users are solely responsible for complying with Nike's terms of service and any applicable laws or regulations.</li>

                <h3>3. Privacy and Data Usage:</h3>
                <li>Provider respects the privacy of its users and is committed to protecting their personal information. Provider does not access or read private or personal data of users except for the necessary information required for client verification purposes. Any data collected is handled in accordance with our Privacy Policy.</li>
                
                <h3>4. User Responsibility:</h3>
                <li>Users acknowledge and agree that they are solely responsible for their use of the Extension and any consequences thereof. Users agree to use the Extension only for its intended purpose and in compliance with all applicable laws and regulations.</li>

                <h3>5. Security Measures:</h3>
                <li>Provider maintains stringent security measures to protect the integrity and confidentiality of user data. However, users acknowledge and understand that no method of transmission over the internet or electronic storage is 100% secure. While Provider takes reasonable precautions to prevent security breaches, we cannot guarantee that unauthorized access, hacking, data loss, or other security breaches will never occur. To ensure maximum security: When the user is not actively using the extension, make sure to toggle the extension off.</li>

                <h3>6. Affiliates:</h3>
                <li>The Extension utilizes affiliate tags through which Provider earns revenue from affiliate partners. Users understand and agree that this is the primary method through which Provider sustains its business, allowing the Extension to remain free for use. Users further acknowledge that Provider may receive compensation for referrals made through the Extension.</li>

                <h3>7. Jurisdiction:</h3>
                <li>These terms and conditions shall be governed by and construed in accordance with the laws of China. Any dispute arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of China.</li>

                <h3>8. Prohibited Activities:</h3>
                <li>Users agree not to engage in any activities that may disrupt or interfere with the proper functioning of the Extension or its associated services. This includes but is not limited to, attempting to reverse engineer, decompile, or otherwise tamper with the code of the Extension.</li>

                <h3>9. Modifications:</h3>
                <li>Provider reserves the right to modify or update these terms and conditions at any time without prior notice. Users are encouraged to review these terms periodically for any changes. Continued use of the Extension after any modifications to the terms constitutes acceptance of those modifications.</li>
                <li>By installing and using the Extension, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions. If you have any questions or concerns regarding these terms, please contact us at eldensoftware@gmail.com</li>
              </div>
              
              <button className={SubmitFormCSS["atc-tos-agree-btn"]} type="button" onClick={handleDownload}>Agree and Download</button>
              <button className={SubmitFormCSS["atc-tos-cancel-btn"]} type="button" onClick={() => setIsATCToSMenuActive(false)}>Cancel</button>
            </div>
          </div>
        : null}
      </form>
    </div>
  );
}

export default SubmitForm;