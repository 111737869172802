import { useState, useEffect } from "react";
import axios from "axios";
import SkusCSS from "../css/skus.module.css";
import searchImg from "../assets/search.svg";
import downloadImg from "../assets/download.svg";
import menuDotsImg from "../assets/menu-dots.svg";
import AOS from "aos";
import "aos/dist/aos.css";

import { Cropper, getCroppedImg } from "react-cropper-custom";
import "react-cropper-custom/dist/index.css";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


function Skus(props) {
  const [inputSKUSearchValue, setInputSKUSearchValue] = useState("");
  const [inputParameters, setInputParameters] = useState({
    A: "0",
    B: "0",
    C: "0",
    D: "0",
    E: "0",
    F: "0",
    G: "0",
    H: "0"
  });
  const [colorValue, setColorValue] = useState("#ffffff");
  const [checkBoxState, setCheckBoxState] = useState(false);
  const [accessToMenu, setAccessToMenu] = useState(false);

  const [skuImages, setSKUImages] = useState([]);
  const [skuImagesMirror2, setSKUImagesMirror2] = useState([]);
  const [skuImagesMirror3, setSKUImagesMirror3] = useState([]);

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const [showInfo, setShowInfo] = useState("");
  const [parameterMenu, setParameterMenu] = useState(false);

  const versionCroppedImagesParameters = "v0.0.2";

  const handleSKUSearchImg = e => {
    e.preventDefault();
    setIsSearchClicked(true);

    setSKUImages([]);
    setSKUImagesMirror2([]);
    setSKUImagesMirror3([]);

    const croppedImagesParameters = JSON.parse(localStorage.getItem(`croppedimagesparameters${versionCroppedImagesParameters}`)) || [];
    
    if (croppedImagesParameters.length) {
      setCropImages(croppedImagesParameters);
    } else {
      setCropImages([]);
    }

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/skuimage",
      headers: { 
        "Content-Type": "application/json"
      },
      params: {
        sku: inputSKUSearchValue,
        color: colorValue,
        transparent: checkBoxState
      },
      data: { inputParameters }
    };

    axios(config)
      .then((res) => {
        try {
          if (res.data.data === "null") return setIsSearchClicked(false);

          setIsSearchClicked(false);

          setSKUImages(res.data.data.mirror1);
          // const newCropImages = res.data.data.mirror1.map((el, i) => ({x: 0, y: 0, width: 0, height: 0, imageSrc: el, imageIndex: i}));
          const newCropImages = [];

          for (let i = 0; i < 20; i++) {
            newCropImages.push({x: 0, y: 0, width: 0, height: 0, imageSrc: res.data.data.mirror1[i] || "", imageIndex: i});
          }

          const croppedImagesParameters = JSON.parse(localStorage.getItem(`croppedimagesparameters${versionCroppedImagesParameters}`)) || [];
    
          if (!croppedImagesParameters.length) {
            localStorage.setItem(`croppedimagesparameters${versionCroppedImagesParameters}`, JSON.stringify(newCropImages));
            
            setCropImages(newCropImages);
          } else {
            setCropImages(croppedImagesParameters);
          }
          

          // const filtered_image_urls = res.data.data.mirror2.filter(el => el !== 'undefined');
          // const image_urls = filtered_image_urls.map(el => el.replace(/\/t_default\/u_[a-f0-9-]+,c_scale,fl_relative,w_1\.0,h_1\.0,fl_layer_apply/, ''));
          // setSKUImagesMirror2(image_urls);
          setSKUImagesMirror2(res.data.data.mirror2);

          setSKUImagesMirror3(res.data.data.mirror3);
        } catch (error) { }

        // if (e.target.name === "mirror#1") {
        //   setSKUImages(res.data.data);
        //   setIsSearchClicked(false);
        // } else {
        //   const filtered_image_urls = res.data.data.filter(el => el !== 'undefined');
        //   const image_urls = filtered_image_urls.map(el => el.replace(/\/t_default\/u_[a-f0-9-]+,c_scale,fl_relative,w_1\.0,h_1\.0,fl_layer_apply/, ''));

        //   setSKUImagesMirror2(image_urls);
        //   setIsSearchClickedMirror2(false);
        // }
      }).catch(() => { setIsSearchClicked(false) });
  };

  const [isDownloadActive, setIsDownloadActive] = useState(false);

  useEffect(() => {
    if (!isDownloadActive) return;
    setIsDownloadActive(false);
    setShowEditorMenu(false);

    let customFileName = imageName;
    if (imageName === "") {
      customFileName = window.prompt('Enter file name:', `image`);
      if (customFileName === null) return;
    }
    
    let a = document.createElement("a");
    a.href = img;
    a.download = `${customFileName}.jpg`;
    a.click();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadActive]);

  const handleDownload = async e => {
    if (e?.currentTarget?.id === "download-all-btn") {
      const downloadImage = async (el, i, croppedImage) => {
        return new Promise((resolve) => {
          const image = el;
          setWrapperImg({imageSrc: image, imageIndex: i});

          setPresentParameters({
            x: croppedImage.x,
            y: croppedImage.y,
            width: croppedImage.width, 
            height: croppedImage.height,
            isActive: true,
            imageIndex: i
          });

          setTimeout(() => {
            resolve();
          }, 2000);
        });
      }

      for (const [i, el] of skuImages.entries()) {
        const croppedImage = cropImages.filter(_ => _.imageIndex === i);
        await downloadImage(el, i, croppedImage[0]);
      }
    } else if (e?.currentTarget?.name === "second-download-btn") {
      const image = e.currentTarget.id.includes("http") ? e.currentTarget.id : e.currentTarget.id;
      const prevZoom = zoom;
      setWrapperImg({imageSrc: image, imageIndex: 0});
      setImg(image);
  
      setShowEditorMenu(!showEditorMenu);
      setZoom(zoom + .01);
      setTimeout(() => {
        setZoom(prevZoom);
        
        setTimeout(() => {
          setIsDownloadActive(true);
        }, 1000);
      }, 1000);
    } else if (e?.currentTarget?.id.includes("http")) {
      const imageUrl = e.currentTarget.id;

      try {
        const response = await fetch(imageUrl);
  
        if (!response.ok) {
          throw new Error('Image not found');
        }
  
        const imageBlob = await response.blob();
  
        const customFileName = window.prompt('Enter file name:', `image`);
        if (customFileName === null) return;
  
        const downloadLink = document.createElement('a');
        const imageUrlObjectURL = URL.createObjectURL(imageBlob);
  
        downloadLink.setAttribute('download', `${customFileName}.jpg`);
        downloadLink.href = imageUrlObjectURL;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(imageUrlObjectURL);
        document.body.removeChild(downloadLink);
      } catch (err) { }
    } else {
      const image = e?.currentTarget?.id ? e.currentTarget.id : img;
      
      let customFileName = imageName;
      if (imageName === "") {
        customFileName = window.prompt('Enter file name:', `image`);
        if (customFileName === null) return;
      }
      
      let a = document.createElement("a");
      a.href = e?.currentTarget?.id ? image : image;
      a.download = `${customFileName}.jpg`;
      a.click();
    }
  };

  useEffect(() => {
    try {
      if (props.data.roles.includes("editor")) setAccessToMenu(true);
      if (props.data.roles.includes("admin")) setAccessToMenu(true);
    } catch (error) { }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCheckBoxState(false);
  }, [colorValue]);

  useEffect(() => {
    setTimeout(() => {
      setShowInfo("");
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    AOS.init({      
      offset: 300,
      duration: 100,
      easing: 'ease-in-out-quart',
    });
  }, []);

  // Image Edit
  const [showEditorMenu, setShowEditorMenu] = useState(false);
  const [wrapperImg, setWrapperImg] = useState({imageSrc: "", imageIndex: 0});
  const [img, setImg] = useState("");
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1);
  const [imageName, setImageName] = useState("");

  const [autoSave, setAutoSave] = useState(false);
  const [cropImages, setCropImages] = useState([]);
  const [presentParameters, setPresentParameters] = useState({x: 0, y: 0, width: 0, height: 0, isActive: false, imageIndex: 0});

  useEffect(() => {
    if (!presentParameters.isActive) return;
    setAutoSave(false);
    
    const image = new Image();
    image.src = wrapperImg.imageSrc;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = presentParameters.width ? presentParameters.width : 1500;
    canvas.height = presentParameters.height ? presentParameters.height : 1500;
    
    ctx.drawImage(
      image,
      presentParameters.x * scaleX,
      presentParameters.y * scaleY,
      presentParameters.width ? presentParameters.width : 1500 * scaleX,
      presentParameters.height ? presentParameters.height :1500 * scaleY,
      0,
      0,
      presentParameters.width ? presentParameters.width : 1500,
      presentParameters.height ? presentParameters.height : 1500
    );

    const croppedImage = canvas.toDataURL('image/jpg');
    // setImg(croppedImage);

    let customFileName = imageName;
    if (imageName === "") {
      customFileName = window.prompt('Enter file name:', `image`);
      if (customFileName === null) return;
    }
    
    let a = document.createElement("a");
    a.href = croppedImage;
    a.download = `${customFileName}.jpg`;
    a.click();

    setPresentParameters({
      x: presentParameters.x,
      y: presentParameters.y,
      width: presentParameters.width,
      height: presentParameters.height,
      isActive: false,
      imageIndex: presentParameters.imageIndex
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentParameters]);

  useEffect(() => {
    const croppedImagesParameters = JSON.parse(localStorage.getItem(`croppedimagesparameters${versionCroppedImagesParameters}`)) || [];

    if (croppedImagesParameters.length) {
      setCropImages(croppedImagesParameters);
    }
  }, []);

  const onCropComplete = async (croppedArea) => {
    try {
      if (autoSave) {
        const croppedImagesParameters = JSON.parse(localStorage.getItem(`croppedimagesparameters${versionCroppedImagesParameters}`)) || [];

        if (!croppedImagesParameters.length) {
          const newCropImages = cropImages.map(el => {
            if (wrapperImg.imageIndex == el.imageIndex) {
              return {
                x: croppedArea.x,
                y: croppedArea.y,
                width: croppedArea.width,
                height: croppedArea.height,
                imageSrc: el.imageSrc,
                imageIndex: el.imageIndex
              };
            } else {
              return el;
            }
          });

          localStorage.setItem(`croppedimagesparameters${versionCroppedImagesParameters}`, JSON.stringify(newCropImages));

          setCropImages(newCropImages);
        } else {
          const newCropImages = croppedImagesParameters.map(el => {
            if (wrapperImg.imageIndex == el.imageIndex) {
              return {
                x: croppedArea.x,
                y: croppedArea.y,
                width: croppedArea.width,
                height: croppedArea.height,
                imageSrc: el.imageSrc,
                imageIndex: el.imageIndex
              };
            } else {
              return el;
            }
          });
  
          localStorage.setItem(`croppedimagesparameters${versionCroppedImagesParameters}`, JSON.stringify(newCropImages));
  
          setCropImages(newCropImages);
        }
      }

      cropImage(croppedArea);
    } catch (e) {
      // console.error(e);
    }
  };

  const handleImage = async (e) => {
    const prevZoom = zoom;

    const responseData = await axios.post('/api/getimage', { url: e.target.src });

    setWrapperImg({imageSrc: 'data:image/jpg;base64,' + responseData.data.data, imageIndex: e.target.name});

    setShowEditorMenu(!showEditorMenu);
    setZoom(zoom + .01);
    setTimeout(() => {
      setZoom(prevZoom);
    }, 1000);
  }

  const handleEscapeKeyPress = (e) => {
    if (e.key === 'Escape'){
      setShowEditorMenu(false);
      setParameterMenu(false);
      setImg(false);
    };
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKeyPress);

    return () => { window.removeEventListener('keydown', handleEscapeKeyPress); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prevZoom = zoom;
    setZoom(1);
    
    setTimeout(() => {
      setZoom(prevZoom);
    }, 2000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspect])
  // Image Edit

  const cropImage = (crop) => {
    const image = new Image();
    image.crossOrigin = `${Math.random()}`;
    image.src = wrapperImg.imageSrc;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width;
    canvas.height = crop.height;
    
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImage = canvas.toDataURL('image/jpg');
    setImg(croppedImage);
  };

  return (
    <>
      <h2 className={SkusCSS["sku-search-title"]}>Search SKU Images</h2>
      <form onSubmit={handleSKUSearchImg} action="" method="post" className={SkusCSS["search-container"]}>
        <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValue} onChange={e => setInputSKUSearchValue(e.target.value)}/>
        <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" />
      </form>
      <div className={isSearchClicked ? SkusCSS["search-spin"] : ""}>
        <span className={SkusCSS["search-spinner"]}></span>
      </div>

      <div className={SkusCSS["skus"]}>
        <div className={SkusCSS["search-sku-imgs"]}>
          <h2 className={SkusCSS["sku-search-title"]}>Mirror #1</h2>
          {/* <div className={SkusCSS["search-container"]}>
            <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValue} onChange={e => setInputSKUSearchValue(e.target.value)}/>
            <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#1" />
          </div> */}

          <div className={SkusCSS["sku-image-container"]}>
            {skuImages.length ? (
              skuImages.map((img, index) => {
                return (
                  <div key={index} className={SkusCSS["sku-image-box"]}>
                    {accessToMenu ? 
                      <img onClick={handleImage} style={{ cursor: "pointer" }} className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} name={index} />
                    : <img className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} />}
                    
                    <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror1-${index}`} alt="download-img" id={img}/>
                    <img onClick={handleDownload} className={SkusCSS["second-download-img"]} src={downloadImg} name={"second-download-btn"} alt="download-img" id={img}/>
                  </div>
                )
              })
            ) : <div></div>}
          </div>
          
          {skuImages.length && accessToMenu ? 
            <>
              <h2 className={SkusCSS["download-all-btn"]} onClick={handleDownload} id={"download-all-btn"}>Download All Images</h2>
              <input
                value={imageName}
                onChange={(e) => setImageName(e.target.value)}
                className={SkusCSS["sku-name"]}
                style={{backgroundColor: "#343434"}}
                type="text"
                name="sku-name"
                placeholder="name"
              />
            </>
          : <></>}
        </div>
      </div>

      <div className={SkusCSS["skus"]}>
        <div className={SkusCSS["search-sku-imgs"]}>
          <h2 className={SkusCSS["sku-search-title"]}>Mirror #2</h2>
          {/* <div className={SkusCSS["search-container"]}>
            <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValueMirror2} onChange={e => setInputSKUSearchValueMirror2(e.target.value)}/>
            <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#2" />
          </div> */}

          <div className={SkusCSS["sku-image-container"]}>
            {skuImagesMirror2.length ? (
              skuImagesMirror2.map((img, index) => {
                return (
                  <div key={index} className={SkusCSS["sku-image-box"]}>
                    {/* <img onClick={handleImage} className={SkusCSS["sku-img"]} src={img} alt={img} /> */}

                    {accessToMenu ? 
                      <img onClick={handleImage} style={{ cursor: "pointer" }} className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} />
                    : <img className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} />}

                    <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror2-${index}`} alt="download-img" id={img}/>
                    <img onClick={handleDownload} className={SkusCSS["second-download-img"]} src={downloadImg} name={"second-download-btn"} alt="download-img" id={img}/>
                  </div>
                )
              })
            ) : <div></div>}
          </div>
        </div>
      </div>

      <div className={SkusCSS["skus"]}>
        <div className={SkusCSS["search-sku-imgs"]}>
          <h2 className={SkusCSS["sku-search-title"]}>Mirror #3</h2>
          {/* <div className={SkusCSS["search-container"]}>
            <input className={SkusCSS["sku-search-input"]} type="text" name="search-sku-images" id="sku-search" placeholder="SKU" value={inputSKUSearchValueMirror3} onChange={e => setInputSKUSearchValueMirror3(e.target.value)}/>
            <img onClick={handleSKUSearchImg} className={SkusCSS["search-img"]} src={searchImg} alt="search-img" name="mirror#3"/>
          </div> */}

          <div className={SkusCSS["sku-image-container"]}>
            {skuImagesMirror3.length ? (
              skuImagesMirror3.map((img, index) => {
                return (
                  <div key={index} className={SkusCSS["sku-image-box"]}>
                    {/* <img onClick={handleImage} className={SkusCSS["sku-img"]} src={`data:image/png;base64,${img}`} alt={`img-${index}`} /> */}
                    
                    {accessToMenu ? 
                      <img onClick={handleImage} style={{ cursor: "pointer" }} className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} />
                    : <img className={SkusCSS["sku-img"]} src={img} alt={`img-${index}`} />}

                    <img onClick={handleDownload} className={SkusCSS["download-img"]} src={downloadImg} name={`mirror3-${index}`} alt="download-img" id={img}/>
                    <img onClick={handleDownload} className={SkusCSS["second-download-img"]} src={downloadImg} name={"second-download-btn"} alt="download-img" id={img}/>
                  </div>
                )
              })
            ) : <div></div>}
          </div>
        </div>
      </div>

      {showEditorMenu ? 
        <div onClick={() => setShowEditorMenu(false)} className={SkusCSS["image-editor-container"]}>
          <div onClick={e => e.stopPropagation()} className={SkusCSS["image-editor"]}>
            <div className={SkusCSS["wrapper"]}>
              <Cropper
                src={wrapperImg.imageSrc}
                zoom={zoom}
                minZoom={1}
                maxZoom={3}
                aspect={aspect}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            {/* {img.length > 100 ?
              <img className={SkusCSS["cropped-img"]} src={img} alt="cropped-img" />
            : <></>} */}
            
            <div className={SkusCSS["input-box"]}>
              <input value={imageName} onChange={(e) => setImageName(e.target.value)} className={SkusCSS["sku-name"]} type="text" name="sku-name" placeholder="name" />
              <div className={SkusCSS["input-aspect-ratio"]}>
                <span style={{ marginRight: "12px" }}>Aspect</span>
                <select
                  defaultValue={aspect}
                  onChange={(e) => setAspect(parseFloat(e.target.value))}
                >
                  <option value={1 / 1}>1:1</option>
                  <option value={10 / 16}>16:10</option>
                  <option value={11 / 10}>11:10</option>
                </select>
              </div>
              <input
                className={SkusCSS["input-zoom"]}
                type="range"
                min={1}
                max={3}
                step={0.01}
                value={zoom}
                onChange={(e) => setZoom(parseFloat(e.target.value))}
              />
              <input
                className={SkusCSS["input-number"]}
                type="number"
                min={1}
                max={3}
                step={0.01}
                value={zoom}
                onChange={(e) => {
                  if (!e.target.value) return setZoom(1);
                  setZoom(parseFloat(e.target.value))
                }}
              />
              <div style={{marginTop: "6px"}} className={SkusCSS["parameter-checkbox-box"]}>
                <input className={SkusCSS["parameter-checkbox"]} checked={autoSave} onChange={() => {setAutoSave(!autoSave)}} type="checkbox" />
              </div>
              <h2 className={SkusCSS["download-btn"]} onClick={handleDownload}>Download</h2>
              <h2 className={SkusCSS["close-btn"]} onClick={() => { setShowEditorMenu(false); setImg("") }}>Close</h2>
            </div>
          </div>
        </div>
      : <></>}

      {accessToMenu ? 
        <div className={SkusCSS["bottom-container"]}>
          <div className={SkusCSS["menu-box"]}>
            <div onClick={() => setParameterMenu(!parameterMenu)} className={SkusCSS["menu-btn"]}>
              <img className={SkusCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${SkusCSS["parameter-container"]} ${parameterMenu ? SkusCSS["parameter-container-show"] : ""}`}>
            <h2 className={SkusCSS["parameter-form-title"]} htmlFor="region">Image Parameters</h2>
            
            <div className={SkusCSS["image-option-box"]}>
              <div>
                <label htmlFor="image-a">A</label>
                <input type="number" name="image-a" min="0"
                  value={inputParameters.A}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        A: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-b">B</label>
                <input type="number" name="image-b" min="0"
                  value={inputParameters.B}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        B: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-c">C</label>
                <input type="number" name="image-c" min="0"
                  value={inputParameters.C}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        C: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-d">D</label>
                <input type="number" name="image-d" min="0"
                  value={inputParameters.D}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        D: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-e">E</label>
                <input type="number" name="image-e" min="0"
                  value={inputParameters.E}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        E: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-f">F</label>
                <input type="number" name="image-f" min="0"
                  value={inputParameters.F}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        F: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-g">G</label>
                <input type="number" name="image-g" min="0"
                  value={inputParameters.G}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        G: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div>
                <label htmlFor="image-h">H</label>
                <input type="number" name="image-h" min="0"
                  value={inputParameters.H}
                  onChange={(e) => {
                    setInputParameters(prevState => {
                      return {
                        ...prevState,
                        H: e.target.value
                      }
                    })
                  }}
                />
              </div>
            </div>

            <div className={SkusCSS["parameter-checkbox-box"]}>
              <input className={SkusCSS["parameter-color"]} value={colorValue} onChange={(e) => setColorValue(e.target.value)} type="color" name="" id="" />
              <input className={SkusCSS["parameter-checkbox"]} checked={checkBoxState} onChange={() => {setCheckBoxState(!checkBoxState)}} type="checkbox" name="" id="" />
            </div>
            <button className={SkusCSS["reset-btn"]} type="button" onClick={() => {
              setInputParameters({ A: "0", B: "0", C: "0", D: "0", E: "0", F: "0", G: "0", H: "0" });
              setColorValue("#ffffff");
              setCheckBoxState(false);
            }}>Reset</button>
            <button className={SkusCSS["cancel-btn"]} type="button" onClick={() => setParameterMenu(false)}>Close</button>
          </div>
        </div>
      : <></>}
    </>
  );
}

export default Skus;