import { useState, useEffect } from "react";
import axios from "axios";
import WebhooksCSS from "../css/webhooks.module.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Webhooks() {
  const [inputValue, setInputValue] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [showInfo, setShowInfo] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    setIsClicked(true);

    setTimeout(() => {
      try {
        const data = JSON.stringify(JSON.parse(inputValue));
  
        const config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: '/api/webhooks',
          headers: { 
            'Content-Type': 'application/json'
          },
          data: data
        };
  
        axios(config)
          .then(() => setShowInfo("sent"))
          .catch(() => setShowInfo("error"));
      } catch (error) { setShowInfo("sent"); }
      setIsClicked(false);
    }, 1000);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowInfo("");
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    AOS.init({      
      offset: 300,
      duration: 100,
      easing: 'ease-in-out-quart',
    });
  }, []);

  useEffect(() => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/api/webhooks',
      headers: { }
    };
    
    axios(config)
      .then(res => setInputValue(JSON.stringify(res.data)))
      .catch(() => { });
  }, []);

  return (
    <div className={WebhooksCSS["webhooks"]}>
        <form className={WebhooksCSS["form-container"]} action="" method="post" onSubmit={handleSubmit}>
          <label htmlFor="webhookInput">Enter the Webhooks</label>
          <textarea
            type="text"
            id="webhookInput"
            name="webhookInput"
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            placeholder='[
              "EXAMPLE1",
              "EXAMPLE2"
]'
            required
          />
          <button className={isClicked ? WebhooksCSS["spin"] : ""} type="submit">
            {showInfo === "sent" && <div className={WebhooksCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Webhooks updated successfully</p></div>}
            {showInfo === "error" && <div className={WebhooksCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
            Update
            <span className={WebhooksCSS["spinner"]}></span>
          </button>
        </form>
    </div>
  );
}

export default Webhooks;