import { createSlice } from "@reduxjs/toolkit";

const channelSlice = createSlice({
  name: "channel",
  initialState: {
    channelRegion: "",
    channelId: "",
    channelName: ""
  },
  reducers: {
    updateChannel: (state, action) => {
      state.channelRegion = action.payload.channelRegion;
      state.channelId = action.payload.channelId;
      state.channelName = action.payload.channelName;
    }
  }
});

export const { updateChannel } = channelSlice.actions;
export default channelSlice.reducer;