import { useState, useEffect } from "react";
import axios from "axios";
import HPCSS from "../css/hp.module.css";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function HP() {
  const [laptopsInputValue, setLaptopsInputValue] = useState({
    US: []
  });
  // const [isClicked, setIsClicked] = useState([false]);
  // const [showInfo, setShowInfo] = useState([""]);

  const [regionList] = useState(["US"]);
  const [showWebhooks, setShowWebhooks] = useState([]);
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();

    const region = e.currentTarget.name.split("-")[0];
    const category = e.currentTarget.name.split("-")[1];
    const action = e.currentTarget.name.split("-")[2];
    const label = e.currentTarget.id;
    let data;

    switch (category) {
      case "LAPTOPS":
        data = laptopsInputValue[region].find(el => el.label === label);
        break;
    
      default:
        break;
    }
    
    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/hpwebhooks?region=${region}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action,
          category,
          data: {label: data.label, value: data.value}
        }
      };

      axios(config).then(() => {
        setReloadWebhooks(!reloadWebhooks);
      }).catch(() => { });
    } catch (error) { }

    // const category = e.currentTarget.id;

    // switch (category) {
    //   case "LAPTOPS":
    //     setIsClicked([true]);
    //     break;
    
    //   default:
    //     break;
    // }
    
    // setTimeout(() => {
    //   try {
    //     const data = JSON.stringify(JSON.parse(category === "LAPTOPS" ? laptopsInputValue : null));
  
    //     const config = {
    //       method: "post",
    //       maxBodyLength: Infinity,
    //       url: "/api/hpwebhooks?region=US",
    //       headers: { 
    //         "Content-Type": "application/json"
    //       },
    //       data: {
    //         category,
    //         data
    //       }
    //     };
  
    //     axios(config)
    //       .then((res) => {
    //         switch (category) {
    //           case "LAPTOPS":
    //             setShowInfo(["sent"]);
    //             break;
            
    //           default:
    //             break;
    //         }
    //       }).catch((err) => {
    //         switch (category) {
    //           case "LAPTOPS":
    //             setShowInfo(["error"]);
    //             break;
            
    //           default:
    //             break;
    //         }
    //       });
    //   } catch (error) {
    //     switch (category) {
    //       case "LAPTOPS":
    //         setShowInfo(["error"]);
    //         break;
        
    //       default:
    //         break;
    //     }
    //   }

    //   switch (category) {
    //     case "LAPTOPS":
    //       setIsClicked([false]);
    //       break;
      
    //     default:
    //       break;
    //   }
    // }, 1000);
  };

  const handleNewWebhookSubmit = (e) => {
    e.preventDefault();

    const label = labelInputValue
    const webhookURL = webhookURLInputValue;
    const region = e.target[2].value;
    const category = e.target[3].value;

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/hpwebhooks?region=${region}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          action: "INSERT",
          category,
          data: { label: label, value: webhookURL },
        },
      };

      axios(config).then((res) => {
        setLabelInputValue("");
        setWebhookURLInputValue("");
        setReloadWebhooks(!reloadWebhooks);
        setUploadMenu(false);
      }).catch(() => { });
    } catch (error) { }
  };

  useEffect(() => {
    setShowWebhooks([]);
    
    const newLaptopsInputValue = { ...laptopsInputValue };

    regionList.forEach((region, index) => {
      setTimeout(() => {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/api/hpwebhooks?region=${region}`,
        };

        axios(config)
          .then((res) => {
            newLaptopsInputValue[region] = res.data[0].webhooks.map(webhook => {return {label: webhook.label, value: webhook.value, readOnly: true}});

            setLaptopsInputValue(newLaptopsInputValue);
          }).catch(() => {}).finally(() => setShowWebhooks(prevState => [...prevState, 1]));
      }, index * 100);
    });

    // const config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: "/api/hpwebhooks?region=US",
    //   headers: { }
    // };
    
    // axios(config)
    //   .then(res => {
    //     setLaptopsInputValue(JSON.stringify(res.data[0].webhooks));
    //   }).catch(() => { });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadWebhooks]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = 'hidden' : document.body.style.overflow = '';
  }, [uploadMenu]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowInfo([""]);
  //   }, 8000);
  // }, [showInfo]);

  return (
    <div className={HPCSS["root-hp-equipment"]}>
      <div className={HPCSS["region-container"]}>
        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={HPCSS["first-background"]}></div> : <></>}

        {showWebhooks.length === regionList.length ? <>
          {regionList.map((region, index) => {
            let title = 'unknown'
            switch (region) {
              case "US":
                title = "HP United States";
                break;

              default:
                break;
            }

            return (
              <div className={HPCSS["region-box"]} key={index}>
                <h2 className={HPCSS["region-title"]}>{title}</h2>

                <div className={HPCSS["webhook"]}>
                  <h3 className={HPCSS["webhook-status"]}>Laptops Webhooks</h3>
                  {laptopsInputValue[region].map((webhook, index) => {
                    return (
                      <form className={HPCSS["webhook-form"]} key={index} action="" method="post" autoComplete="off">
                        <div className={HPCSS["webhook-grid"]}>
                          <label className={HPCSS["webhook-label"]} htmlFor={webhook.label}>{webhook.label}</label>
                          <div className={HPCSS["webhook-row"]}>
                            <input
                              className={HPCSS["webhook-input"]}
                              value={webhook.value}
                              name={region}
                              type="url"
                              id={webhook.label}
                              required 
                              onChange={e => {
                                if (e.target.name === region) {
                                  let newLaptopsInputValue = { ...laptopsInputValue };
                                  const updatedWebhook = laptopsInputValue[region].map(el => {
                                    if (el.label === webhook.label) {
                                      return ({ label: webhook.label, value: e.target.value, readOnly: el.readOnly });
                                    } else {
                                      return el;
                                    }
                                  });
                                  newLaptopsInputValue[region] = updatedWebhook;
                                  setLaptopsInputValue(newLaptopsInputValue);
                                }
                              }}
                              disabled={laptopsInputValue[region].find(el => el.label === webhook.label).readOnly}
                            />
                            <button className={HPCSS["webhook-edit-btn"]} id={webhook.label} name={region} type="button"
                              onClick={e => {
                                let newLaptopsInputValue = { ...laptopsInputValue };
                                const updatedWebhook = laptopsInputValue[e.currentTarget.name].map(el => {
                                  if (el.label === webhook.label) {
                                    return ({ label: webhook.label, value: webhook.value, readOnly: !el.readOnly })
                                  } else {
                                    return el;
                                  }
                                });
                                newLaptopsInputValue[e.currentTarget.name] = updatedWebhook;
                                setLaptopsInputValue(newLaptopsInputValue);
                              }}
                            ><img className={HPCSS["edit-img"]} src={editImg} alt="edit-img"/></button>

                            <button className={HPCSS["webhook-save-btn"]} id={webhook.label} name={region + "-LAPTOPS-UPDATE"} type="submit"
                              onClick={handleSubmit}
                            ><img className={HPCSS["cloud-upload-img"]} src={cloudUploadImg} alt="cloud-upload-img" /></button>

                            <button className={HPCSS["webhook-delete-btn"]} id={webhook.label} name={region + "-LAPTOPS-DELETE"} type="button"
                              onClick={handleSubmit}
                            ><img className={HPCSS["delete-img"]} src={deleteImg} alt="delete-img" /></button>
                          </div>
                        </div>
                      </form>
                    );
                  })}
                </div>
              </div>
            )
          })} </> : <div className={HPCSS["access-spin"]}><span className={HPCSS["access-spinner"]}></span></div>}

        <div className={HPCSS["bottom-container"]}>
          <div className={HPCSS["menu-box"]}>
            <div onClick={() => setUploadMenu(!uploadMenu)} className={HPCSS["menu-btn"]}>
              <img className={HPCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
            </div>
          </div>

          <div className={`${HPCSS["upload-container"]} ${uploadMenu ? HPCSS["upload-container-show"] : ""}`}>
            <h2 className={HPCSS["upload-form-title"]} htmlFor="region">Enter the new webhook</h2>
            
            <form className={HPCSS["upload-form"]} action="" method="post" autoComplete="off" onSubmit={handleNewWebhookSubmit}>
              <input className={HPCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
              <input className={HPCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="url" name="webhook" placeholder="Webhook URL" required />
              
              <select className={HPCSS["upload-select-region"]} name="region">
                <option value="US">United States</option>
              </select>

              <select className={HPCSS["upload-select-status"]} name="status" value={["LAPTOPS"]} multiple readOnly>
                <option value="LAPTOPS">Laptops</option>
              </select>

              <button className={HPCSS["upload-submit-btn"]} type="submit">Upload</button>
              <button className={HPCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
            </form>
          </div>
        </div>

        {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={HPCSS["second-background"]}></div> : <></>}
      </div>

      {/* <form className={HPCSS["form-container"]} action="" method="post" id="LAPTOPS" onSubmit={handleSubmit}>
        <label htmlFor="laptopsInputWebhook">Enter the Laptop Webhooks</label>
        <textarea
            className={HPCSS["webhook-textarea"]}
            type="text"
            id="laptopsInputWebhook"
            name="laptopsInputWebhook"
            value={laptopsInputValue}
            onChange={e => setLaptopsInputValue(e.target.value)}
            placeholder='[
              "EXAMPLE1",
              "EXAMPLE2"
]'
            required
          />
        <button className={isClicked[0] ? HPCSS["spin"] : ""} type="submit">
            {showInfo[0] === "sent" && <div className={HPCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Webhooks updated successfully</p></div>}
            {showInfo[0] === "error" && <div className={HPCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
            Update
            <span className={HPCSS["spinner"]}></span>
        </button>
      </form> */}
    </div>
  );
}

export default HP;