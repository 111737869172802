import { useEffect, useState } from "react";
import axios from "axios";
import HeaderCSS from "../css/header.module.css";
import EldenLogo from "../assets/logo.webp";
import userCircleImg from "../assets/user.svg";

function Header() {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    axios.get("/api/client/user")
      .then(result => {
        const { discordId, discriminator, avatar } = result.data;

        if (avatar === null) {
          setAvatar(`https://cdn.discordapp.com/embed/avatars/${discriminator % 5}.png`);
        } else {
          setAvatar(`https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`);
        }
      }).catch(() => window.location.replace("/"));
  }, [avatar]);

  return (
    <div className={HeaderCSS["header"]}>
      <img className={HeaderCSS["elden-logo"]} src={EldenLogo} alt="elden-logo" />
      {avatar ? <img className={HeaderCSS["author-img"]} src={avatar} alt="author-logo" /> : <img className={HeaderCSS["author-img"]} src={userCircleImg} alt="author-logo" /> }
    </div>
  );
}

export default Header;