import { useState, useEffect } from "react";
import axios from "axios";
import StockNumberCSS from "../css/stocknumber.module.css";
import logoImg from "../assets/logo.webp";
import editImg from "../assets/edit.svg";
import cloudUploadImg from "../assets/cloud-upload.svg";
import deleteImg from "../assets/delete.svg";
import menuDotsImg from "../assets/menu-dots.svg";

function StockNumber() {
  const [showInfo, setShowInfo] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    imageURL: "",
    description: ""
  });
  
  const [uploadMenu, setUploadMenu] = useState(false);
  const [labelInputValue, setLabelInputValue] = useState("");
  const [webhookURLInputValue, setWebhookURLInputValue] = useState("");

  const [webhooks, setWebhooks] = useState([]);
  const [webhooksLoaded, setWebhooksLoaded] = useState(false);
  const [reloadWebhooks, setReloadWebhooks] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/api/stocknumbersend',
      data : {
        title: formData.title,
        imageURL: formData.imageURL,
        description: formData.description,
        webhooks: webhooks.map(el => { return (el.value); })
      }
    };
    
    axios(config)
      .then((res) => {
        setShowInfo("sent");
      })
      .catch((err) => {
        setShowInfo("error");
      }).finally(() => {
        setIsClicked(false);
        setShowInfo("sent");
      });
  };

  const handleUpdateBtn = async (e) => {
    e.preventDefault();
    setWebhooksLoaded(false);
    
    const label = e.target.name;
    const data = webhooks.find(el => el.label === label);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/stocknumberwebhooks",
      data: {
        label: data.label,
        value: data.value,
        action: "UPDATE"
      }
    };
    
    axios(config)
      .then((res) => { })
      .catch((err) => { })
      .finally(() => setReloadWebhooks(!reloadWebhooks));
  };

  const handleDeleteBtn = async (e) => {
    e.preventDefault();
    setWebhooksLoaded(false);
    
    const label = e.target.name;
    const data = webhooks.find(el => el.label === label);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/stocknumberwebhooks",
      data: {
        label: data.label,
        value: data.value,
        action: "DELETE"
      }
    };
    
    axios(config)
      .then((res) => { })
      .catch((err) => { })
      .finally(() => setReloadWebhooks(!reloadWebhooks));
  };

  const handleNewWebhookSubmit = async (e) => {
    e.preventDefault();
    setWebhooksLoaded(false);

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "/api/stocknumberwebhooks",
      data: {
        label: labelInputValue,
        value: webhookURLInputValue,
        action: "CREATE"
      }
    };
    
    axios(config)
      .then((res) => { })
      .catch((err) => { })
      .finally(() => setReloadWebhooks(!reloadWebhooks));
  };

  useEffect(() => {
    setWebhooksLoaded(false);

    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "/api/stocknumberwebhooks",
    };

    axios(config)
      .then((res) => {
        const data = res.data.map(el => ({ label: el.label, value: el.value, readOnly: true }));
        setWebhooks([ ...data ]);
      }).catch(() => { });
  }, [reloadWebhooks]);

  useEffect(() => {
    if (webhooks.length) setWebhooksLoaded(true);
  }, [webhooks]);

  useEffect(() => {
    setTimeout(() => {
      setShowInfo("");
    }, 8000);
  }, [showInfo]);

  useEffect(() => {
    uploadMenu ? document.body.style.overflow = "hidden" : document.body.style.overflow = "";
  }, [uploadMenu]);

  return (
    <div className={StockNumberCSS["stocknumber"]}>
      {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={StockNumberCSS["first-background"]}></div> : <></>}

      <div className={StockNumberCSS["top-section"]}>
        <div className={StockNumberCSS["form-container-block"]}>
          <div className={StockNumberCSS["form-container"]}>
            <form onSubmit={handleSubmit} className={StockNumberCSS["form-container"]} action="" method="post" >
              <label htmlFor="title">Title</label>
              <input type="text" name="title" required value={formData.title} onChange={e => {
                setFormData(prevState => ({ title: e.target.value, imageURL: prevState.imageURL, description: prevState.description }));
              }} />

              <label htmlFor="title">Thumbnail</label>
              <input type="url" name="image-url" required value={formData.imageURL} onChange={e => {
                setFormData(prevState => ({ title: prevState.title, imageURL: e.target.value, description: prevState.description }));
              }} />

              <label htmlFor="description">Description</label>
              <textarea
                type="text"
                name="description"
                value={formData.description}
                onChange={e => {
                  setFormData(prevState => ({ title: prevState.title, imageURL: prevState.imageURL, description: e.target.value }));
                }}
                required
              />

              <button className={isClicked ? StockNumberCSS["spin"] : ""} type="submit">
                {showInfo === "sent" && <div className={StockNumberCSS["info-green"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="500" data-aos-delay="0"><p>Sent successfully</p></div>}
                {showInfo === "error" && <div className={StockNumberCSS["info-red"]} data-aos-once="true" data-aos-offset="0" data-aos="flip-down" data-aos-duration="250" data-aos-delay="0"><p>Error</p></div>}
                Send
                <span className={StockNumberCSS["spinner"]}></span>
              </button>
            </form>
          </div>
        </div>

        <div className={StockNumberCSS["preview-block"]}>
          <div className={StockNumberCSS["preview-container"]}>
            
            { formData.title !== "" ||
              formData.imageURL !== "" ||
              formData.description !== "" ? 
                <div className={StockNumberCSS["preview-box"]}>
                  <div className={StockNumberCSS["preview"]}>
                    {formData.title !== "" ? 
                      <span className={StockNumberCSS["preview-title-box"]}>
                        <div className={StockNumberCSS["preview-title"]}>
                          {formData.title}
                        </div>
                      </span>
                    : null}

                    {formData.description !== "" ? 
                      <div className={StockNumberCSS["preview-desc-box"]}>
                        <div className={StockNumberCSS["preview-desc"]}>
                          {formData.description}
                        </div>
                      </div>
                    : null}

                    <div className={StockNumberCSS["prview-footer-box"]}>
                      <img className={StockNumberCSS["preview-footer-logo"]} src={logoImg} alt="logo-img" />

                      <span className={StockNumberCSS["preview-footer-text"]}>Elden Monitors Stock Numbers</span>
                    </div>

                    {formData.imageURL !== "" ?
                      <div className={StockNumberCSS["preview-thumbnail-box"]}>
                        <img className={StockNumberCSS["preview-thumbnail"]} src={formData.imageURL} alt="thumbnail" />
                      </div>
                    : null}
                  </div>
                </div>
            : null}
          </div>
        </div>
      </div>


      <div className={StockNumberCSS["webhook-container"]}>
        <div className={StockNumberCSS["webhook"]}>
          <h3 className={StockNumberCSS["webhook-title"]}>Webhook URL</h3>
          
          {webhooksLoaded ? webhooks.map((webhook, index) => {
            return (
              <form
                className={StockNumberCSS["webhook-form"]}
                key={index}
                action=""
                method="post"
              >
                <div className={StockNumberCSS["webhook-grid"]}>
                  <label
                    className={StockNumberCSS["webhook-label"]}
                    htmlFor={webhook.label}
                  >
                    {webhook.label}
                  </label>
                  
                  <div className={StockNumberCSS["webhook-row"]}>
                    <input
                      className={StockNumberCSS["webhook-input"]}
                      value={webhook.value}
                      name={webhook.value}
                      type="url"
                      id={webhook.label}
                      required
                      onChange={(e) => {
                        if (e.target.name === webhook.value) {
                          let newWebhooks = webhooks;

                          const updatedWebhook = newWebhooks.map((el) => {
                            if (el.label === webhook.label) {
                              return {
                                label: webhook.label,
                                value: e.target.value,
                                readOnly: el.readOnly
                              };
                            } else {
                              return el;
                            }
                          });

                          newWebhooks = updatedWebhook;
                          setWebhooks(newWebhooks);
                        }
                      }}
                      disabled={webhooks.find((el) => el.label === webhook.label).readOnly}
                    />
                    <button
                      className={StockNumberCSS["webhook-edit-btn"]}
                      type="button"
                      onClick={(e) => {
                        let newWebhooks = webhooks;
                        
                        const updatedWebhook = webhooks.map((el) => {
                          if (el.label === webhook.label) {
                            return {
                              label: webhook.label,
                              value: webhook.value,
                              readOnly: !el.readOnly,
                            };
                          } else {
                            return el;
                          }
                        });

                        newWebhooks = updatedWebhook;
                        setWebhooks(newWebhooks);
                      }}
                    >
                      <img
                        className={StockNumberCSS["edit-img"]}
                        src={editImg}
                        alt="edit-img"
                      />
                    </button>
                    <button
                      className={StockNumberCSS["webhook-save-btn"]}
                      type="submit"
                      name={webhook.label}
                      onClick={handleUpdateBtn}
                    >
                      <img
                        className={StockNumberCSS["cloud-upload-img"]}
                        src={cloudUploadImg}
                        alt="cloud-upload-img"
                      />
                    </button>
                    <button
                      className={StockNumberCSS["webhook-delete-btn"]}
                      type="button"
                      name={webhook.label}
                      onClick={handleDeleteBtn}
                    >
                      <img
                        className={StockNumberCSS["delete-img"]}
                        src={deleteImg}
                        alt="delete-img"
                      />
                    </button>
                  </div>
                </div>
              </form>
            );
          }) : <div className={StockNumberCSS["access-spin"]}><span className={StockNumberCSS["access-spinner"]}></span></div>}
        </div>       
      </div>

      <div className={StockNumberCSS["menu-container"]}>
        <div className={StockNumberCSS["menu-box"]}>
          <div onClick={() => setUploadMenu(!uploadMenu)} className={StockNumberCSS["menu-btn"]}>
            <img className={StockNumberCSS["menu-dots-img"]} src={menuDotsImg} alt="menu-dots-img" />
          </div>
        </div>

        <div className={`${StockNumberCSS["upload-container"]} ${uploadMenu ? StockNumberCSS["upload-container-show"] : ""}`}>
          <h2 className={StockNumberCSS["upload-form-title"]} htmlFor="webhook">Enter the new webhook</h2>
          
          <form className={StockNumberCSS["upload-form"]} action="" method="post" onSubmit={handleNewWebhookSubmit}>
            <input className={StockNumberCSS["upload-input-name"]} value={labelInputValue} onChange={e => setLabelInputValue(e.target.value)} type="text" name="label" placeholder="Label" required />
            <input className={StockNumberCSS["upload-input-webhook"]} value={webhookURLInputValue} onChange={e => setWebhookURLInputValue(e.target.value)} type="url" name="webhook" placeholder="Webhook URL" required />

            <button className={StockNumberCSS["upload-submit-btn"]} type="submit">Upload</button>
            <button className={StockNumberCSS["upload-cancel-btn"]} type="button" onClick={() => setUploadMenu(false)}>Cancel</button>
          </form>
        </div>
      </div>
        
      {uploadMenu ? <div onClick={() => setUploadMenu(false)} className={StockNumberCSS["second-background"]}></div> : <></>}
    </div>
  );
}

export default StockNumber;
