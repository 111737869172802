import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import NavbarCSS from "../css/navbar.module.css";
import userCircleImg from "../assets/user.svg";
import barcodeScanImg from "../assets/barcode-scan.svg";
import linkHorizontalImg from "../assets/link-horizontal.svg";
import usersImg from "../assets/users.svg";
import productsImg from "../assets/products.svg";
import sneakerImg from "../assets/sneaker.svg";
import refurbishImg from "../assets/refurbish.svg";
import HPImg from "../assets/hp.svg";
import inventoryImg from "../assets/inventory.svg";
import imageImg from "../assets/image.svg";
import searchImg from "../assets/search0.svg";
import adidasTrainingImg from "../assets/adidas-training.svg";
import twitterImg from "../assets/twitter.svg";
import linkHorizontalSlashImg from "../assets/link-horizontal-slash.svg";

function Navbar() {
  const [avatar, setAvatar] = useState(null);
  const [dropDownList, setDropDownList] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const navigate = useNavigate();

  const handleDropDownList = () => {
    setDropDownList(!dropDownList);
  };

  const handleImagesLoad = () => {
    setImagesLoaded(true);
  };

  useEffect(() => {
    axios
      .get("/api/user")
      .then((res) => {
        const { discordId, discriminator, avatar } = res.data;

        if (avatar === null) {
          setAvatar(
            `https://cdn.discordapp.com/embed/avatars/${discriminator % 5}.png`
          );
        } else {
          setAvatar(
            `https://cdn.discordapp.com/avatars/${discordId}/${avatar}.png`
          );
        }
      })
      .catch(() => {
        console.log("NAVBAR run");
        // window.location.replace("/")
      });
  }, []);

  return (
    <div className={`${NavbarCSS["navbar"]} touch`}>
      <div className={NavbarCSS["navbar-top"]}>
        {avatar ? (
          <img
            onClick={handleDropDownList}
            className={NavbarCSS["author-img"]}
            src={avatar}
            alt="author-logo"
          />
        ) : (
          <img
            onClick={handleDropDownList}
            className={NavbarCSS["author-img"]}
            src={userCircleImg}
            alt="author-logo"
          />
        )}
        <div
          className={`${NavbarCSS["dropdown-list"]} ${
            dropDownList ? NavbarCSS["active-dropdown-list"] : ""
          }`}
        >
          <p onClick={() => navigate("/dashboard/admin")}>Dashboard</p>
          <p
            onClick={() => {
              axios.get("/api/logout");
              navigate("/");
            }}
          >
            Sign out
          </p>
        </div>
      </div>

      {imagesLoaded ? (
        <div className={NavbarCSS["navbar-bottom"]}>
          <div
            className={NavbarCSS["main-navlink"]}
            onWheel={(e) => {
              if (e.shiftKey) return;

              if (e.deltaY < 0) {
                let position = e.currentTarget.scrollLeft - 280;

                e.currentTarget.scrollTo({
                  left: position,
                  behavior: "smooth",
                });
              } else {
                let position = e.currentTarget.scrollLeft + 280;

                e.currentTarget.scrollTo({
                  left: position,
                  behavior: "smooth",
                });
              }
            }}
          >
            <NavLink
              to="skus"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["barcode-scan-img"]}
                src={barcodeScanImg}
                alt="barcode-scan-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>SKUs</h3>
            </NavLink>
            <NavLink
              to="urlshortener"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["link-horizontal-slash-img"]}
                src={linkHorizontalSlashImg}
                alt="link-horizontal-slash-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>URL</h3>
            </NavLink>
            {/* <NavLink
              to="webhooks"
              className={({isActive}) => { return isActive ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}` : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`} }
            >
              <img className={NavbarCSS["link-horizontal-img"]} src={linkHorizontalImg} alt="link-horizontal-img" onLoad={handleImagesLoad}  />
              <h3 className={NavbarCSS["navlink-title"]}>Webhooks</h3>
            </NavLink> */}
            <NavLink
              to="access"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["users-img"]}
                src={usersImg}
                alt="users-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Access</h3>
            </NavLink>
            <NavLink
              to="nike-equipment"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["products-img"]}
                src={productsImg}
                alt="products-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Nike Equipment</h3>
            </NavLink>
            <NavLink
              to="nike-shoes"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["sneaker-img"]}
                src={sneakerImg}
                alt="sneaker-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Nike Shoes</h3>
            </NavLink>
            {/* <NavLink
              to="nike-refurbished"
              className={({isActive}) => { return isActive ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}` : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`} }
            >
              <img className={NavbarCSS["refurbish-img"]} src={refurbishImg} alt="refurbish-img" onLoad={handleImagesLoad}  />
              <h3 className={NavbarCSS["navlink-title"]}>Nike Refurbished</h3>
            </NavLink> */}
            {/* <NavLink
              to="hp"
              className={({isActive}) => { return isActive ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}` : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`} }
            >
              <img className={NavbarCSS["hp-img"]} src={HPImg} alt="hp-img" onLoad={handleImagesLoad}  />
              <h3 className={NavbarCSS["navlink-title"]}>HP</h3>
            </NavLink> */}
            <NavLink
              to="stocknumber"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["inventory-img"]}
                src={inventoryImg}
                alt="inventory-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Stock Number</h3>
            </NavLink>
            <NavLink
              to="imagemonitor"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["imagemonitor-img"]}
                src={imageImg}
                alt="imagemonitor-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Image Monitor</h3>
            </NavLink>
            <NavLink
              to="productquery"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["productquery-img"]}
                src={searchImg}
                alt="productquery-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Product Query</h3>
            </NavLink>
            {/* <NavLink
              to="adidas"
              className={({isActive}) => { return isActive ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}` : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`} }
            >
              <img className={NavbarCSS["adidas-img"]} src={adidasTrainingImg} alt="adidas-img" onLoad={handleImagesLoad}  />
              <h3 className={NavbarCSS["navlink-title"]}>Adidas</h3>
            </NavLink> */}
            <NavLink
              to="twitter"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["twitter-img"]}
                src={twitterImg}
                alt="twitter-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>Twitter</h3>
            </NavLink>
            <NavLink
              to="asics"
              className={({ isActive }) => {
                return isActive
                  ? `${NavbarCSS["navlink-active"]} ${NavbarCSS["navlink"]}`
                  : `${NavbarCSS["navlink-inactive"]} ${NavbarCSS["navlink"]}`;
              }}
            >
              <img
                className={NavbarCSS["asics-img"]}
                src={sneakerImg}
                alt="asics-img"
                onLoad={handleImagesLoad}
              />
              <h3 className={NavbarCSS["navlink-title"]}>ASICS</h3>
            </NavLink>
          </div>
        </div>
      ) : null}

      <img
        style={{ display: "none" }}
        src={barcodeScanImg}
        alt="barcode-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={linkHorizontalImg}
        alt="link-hoirzontal-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={usersImg}
        alt="users-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={productsImg}
        alt="products-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={sneakerImg}
        alt="sneaker-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={refurbishImg}
        alt="refurbish-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={HPImg}
        alt="hp-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={inventoryImg}
        alt="inventory-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={imageImg}
        alt="imagemonitor-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={searchImg}
        alt="imagemonitor-img"
        onLoad={handleImagesLoad}
      />
      <img
        style={{ display: "none" }}
        src={adidasTrainingImg}
        alt="adidas-training-img"
        onLoad={handleImagesLoad}
      />
    </div>
  );
}

export default Navbar;
