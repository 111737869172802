import { createSlice } from "@reduxjs/toolkit";

const guildSlice = createSlice({
  name: "guild",
  initialState: {
    guildId: "",
    guildName: ""
  },
  reducers: {
    updateGuild: (state, action) => {
      state.guildId = action.payload.guildId;
      state.guildName = action.payload.guildName
    }
  }
});

export const { updateGuild } = guildSlice.actions;
export default guildSlice.reducer;