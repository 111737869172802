import { useState } from "react";
import arrowImg from "../assets/icon-arrow-down.svg";
import "../css/Default Styles/bottom-section.css";
import "../css/Desktop Styles/bottom-section.css";
import "../css/Tablet Styles/bottom-section.css";
import "../css/Mobile Styles/bottom-section.css";

function BottomSection() {
  const [showAnswer, setShowAnswer] = useState([false, false, false, false, false]);

  const handleClick = (event) => {
    let newAnswer;
    switch (event.target.className.split(' ')[0]) {
      case "qa-first":
        newAnswer = [...showAnswer];
        newAnswer[0] = !showAnswer[0];
        setShowAnswer(newAnswer);
        break;
      case "qa-second":
        newAnswer = [...showAnswer];
        newAnswer[1] = !showAnswer[1];
        setShowAnswer(newAnswer);
        break;
      case "qa-third":
        newAnswer = [...showAnswer];
        newAnswer[2] = !showAnswer[2];
        setShowAnswer(newAnswer);
        break;
      case "qa-fourth":
        newAnswer = [...showAnswer];
        newAnswer[3] = !showAnswer[3];
        setShowAnswer(newAnswer);
        break;
      case "qa-fifth":
        newAnswer = [...showAnswer];
        newAnswer[4] = !showAnswer[4];
        setShowAnswer(newAnswer);
        break;
      default:
        break;
    }
  };

  return (
    <div id="faq" className="bottom-section">
      <h1 className="bottom-section-title" data-aos-offset="0" data-aos="zoom-out">Frequently Asked Questions</h1>
      <h3 className="bottom-section-subtitle" data-aos-offset="0" data-aos="zoom-out">Elden monitors pings everything that Nike does. Wether it is shock drop, a change in status, or a change in stock, you will be notified.</h3>
      
      <div className="faq-container">
        <h3 className="title" data-aos-offset="0" data-aos="zoom">Here are Nikes statuses defined</h3>
        <div onClick={handleClick} className="qa-first" data-aos-offset="0" data-aos="fade" data-aos-duration="100" data-aos-delay="10">
          <h3>HOLD Status <img src={arrowImg} alt="arrow-img" /></h3>
          <p style={{display: showAnswer[0] && "block"}}>You cannot buy, do not try to buy.</p>
        </div>
        <div onClick={handleClick} className="qa-second" data-aos-offset="0" data-aos="fade" data-aos-duration="100" data-aos-delay="20">
          <h3>INACTIVE Status <img src={arrowImg} alt="arrow-img" /></h3>
          <p style={{display: showAnswer[1] && "block"}}>Loaded on the backend only, pending a launch date.</p>
        </div>
        <div onClick={handleClick} className="qa-third" data-aos-offset="0" data-aos="fade" data-aos-duration="00" data-aos-delay="30">
          <h3>CANCEL Status <img src={arrowImg} alt="arrow-img" /></h3>  
          <p style={{display: showAnswer[2] && "block"}}>This shoe was cancelled by Nike, reasons unknown.</p>
        </div>
        <div onClick={handleClick} className="qa-fourth" data-aos-offset="0" data-aos="fade" data-aos-duration="100" data-aos-delay="40">
          <h3>ACTIVE Status <img src={arrowImg} alt="arrow-img" /></h3>
          <p style={{display: showAnswer[3] && "block"}}>You can see the product or product page on the frontend.</p>
        </div>
        <div onClick={handleClick} className="qa-fifth" data-aos-offset="0" data-aos="fade" data-aos-duration="100" data-aos-delay="50">
          <h3>CLOSEOUT Status <img src={arrowImg} alt="arrow-img" /></h3>
          <p style={{display: showAnswer[4] && "block"}}>Backend,  you will not see the product page and it is only available on Nikes backend.</p>
        </div>
        <h3 className="gibook-link" data-aos-offset="0" data-aos="zoom">For more info visit our <a tabIndex="-1" href="https://elden-software-solutions.gitbook.io/elden-software-solutions/" target="_blank" rel="noreferrer"><span>Gitbook</span></a></h3>
      </div>
    </div>
  );
}

export default BottomSection;