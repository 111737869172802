import { configureStore } from "@reduxjs/toolkit";
import guildSlice from "./guildSlice";
import channelSlice from "./channelSlice";

const store = configureStore({
  reducer: {
    guild: guildSlice,
    channel: channelSlice
  }
});

export default store;